import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { UserInfo, Facility } from '../auth';

@Component({
  selector: 'app-whitelist',
  templateUrl: './whitelist.component.html',
  styleUrls: ['./whitelist.component.scss']
})
export class WhitelistComponent implements OnInit {

  constructor(public service: AuthService) { }

  selected_user: UserInfo;
  search_field: string;
  search_users: UserInfo[];
  whitelist: UserInfo[];
  whitelistAll: UserInfo[];
  keyword = 'email';
  loading: boolean = false;
  facility: Facility = null;
  error: boolean = false;
  error_message: string;

  ngOnInit() {
    this.syncData()
  }

  async syncData() {
    let facility = await this.service.getFacilityRemote().toPromise();
    this.service.resetFacility(facility);
    this.facility = this.service.getFacility();
    await this.refreshData();
  }


  async refreshData() {
    this.whitelist = await this.service.getWhitelistRemote(this.facility.id).toPromise();
    this.whitelistAll = this.whitelist;
  }

  addToWhitelist() {
    this.postWhiteList()
  }

  async postWhiteList(user: number = null) {
    try {
      await this.service.postWhitelistRemote(this.search_field).toPromise();
      await this.refreshData();
      this.error = false;
      this.error_message = null;
      this.search_field = null;
    } catch (error) {
      this.error = true;
      this.error_message = "Errore";
      if (error.error && error.error.length)
        this.error_message = error.error[0];
    }
  }

  async removeFromWhitelist(email: string) {
    try {
      await this.service.deleteWhitelistRemote(email).toPromise();
      await this.refreshData();
    } catch (error) {
      console.log('Bad post', error)
    }
  }


  onSearchChange(event) {
    if (event == "") {
      this.whitelist = this.whitelistAll;
    }
    else {
      this.whitelist = this.whitelistAll.filter(w => w.email.includes(event));
    }
  }

}
