import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef, MDBModalService, MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth.service';
import { ReservationDetailModalComponent } from 'src/app/modals/reservation-detail/reservation-detail-modal.component';
import { ReservationShareComponent } from 'src/app/modals/reservation-share/reservation-share.component';
import { ReservationWizardCompleteComponent } from 'src/app/modals/reservation-wizard-complete/reservation-wizard-complete.component';
import { ReservationDetailComponent } from 'src/app/reservation-detail/reservation-detail.component';
import { Level, MapPlace, Reservation, ReservationExt, Seasonal } from 'src/app/reservations-table/reservation';
import { ReservationsService } from 'src/app/reservations-table/reservation.service';

@Component({
  selector: 'app-seasonal-table',
  templateUrl: './seasonal-table.component.html',
  styleUrls: ['./seasonal-table.component.scss']
})
export class SeasonalTableComponent implements OnInit, AfterViewInit {

  modalRef: MDBModalRef;

  max_element_per_page: number = 5;

  private _seasonal_reservations: ReservationExt[] = [];
  public get seasonal_reservations(): ReservationExt[] {
    return this._seasonal_reservations;
  }
  @Input()
  public set seasonal_reservations(value: ReservationExt[]) {
    value.forEach(r => {
      r.checkin_formatted = moment(r.checkin).format('DD/MM/YYYY');
      r.checkout_formatted = moment(r.checkout).format('DD/MM/YYYY');
    });
    this._seasonal_reservations = value;
    this.mdbTable.setDataSource(value);
    this.previous = this.mdbTable.getDataSource();
    this.calculatePages();
  }

  headElements = ['ID', 'CLIENTE', 'EMAIL', 'DATA', 'TELEFONO', 'DETTAGLIO', 'SHARE'];
  searchText: string = '';
  previous: string;
  number_pages: number;
  pages: number[];

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;

  constructor(
    private cdRef: ChangeDetectorRef,
    private service: ReservationsService,
    private modalService: MDBModalService,
    private authService: AuthService) {
  }

  @HostListener('input') oninput() {
    this.searchItems();
  }

  ngOnInit() {

  }


  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.max_element_per_page);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
      this.mdbTable.setDataSource(prev);
      this.seasonal_reservations = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.seasonal_reservations = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
      this.mdbTable.setDataSource(prev);
    }

  }

  getSeasonalReservations(seasonal: Seasonal, button: any) {
    if (button.el.nativeElement.ariaExpanded == 'false')
      this.refreshSeasonalReservation(seasonal);
  }

  refreshSeasonalReservation(seasonal) {
    this.service.getSeasonalReservations().subscribe(res =>
      this.seasonal_reservations = this.seasonal_reservations.concat(res)
    );
  }

  calculatePages() {
    this.number_pages = Math.floor(this.seasonal_reservations.length / this.mdbTablePagination.maxVisibleItems);
    let mod = Math.floor(this.seasonal_reservations.length % this.mdbTablePagination.maxVisibleItems);
    if (mod > 0 || this.number_pages == 0)
      this.number_pages++;
    this.pages = [];
    for (let i = 1; i <= this.number_pages; i++) {
      this.pages.push(i);
    }
  }

  goToPage(page: number) {
    let diff = page - this.mdbTablePagination.activePageNumber;
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        this.mdbTablePagination.nextPage();
      }
    }
    if (diff < 0) {
      for (let i = 0; i < Math.abs(diff); i++) {
        this.mdbTablePagination.previousPage();
      }
    }

  }

  getLevelImage(level: Level): string {
    if (level === Level.NRM) {
      return "assets/img/stella_basic.svg";
    }
    else if (level === Level.SLV) {
      return "assets/img/gold.png";
    }
    else if (level === Level.GLD) {
      return "assets/img/platinum.png";
    }
  }

  getPlaceLevelText(place: MapPlace): string {
    if (place.level === Level.NRM) {
      return 'Basic';
    } else if (place.level === Level.SLV) {
      return 'Silver';
    } else if (place.level === Level.GLD) {
      return 'Gold';
    }
  }

  goToReservationDetail(reservation: Reservation) {
    this.modalService.show(ReservationDetailModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg modal-detail',
      data: {
        reservation: reservation,
      }
    })
  }


  goToReservationShare(reservation: Reservation) {
    this.modalService.show(ReservationShareComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      data: {
        reservation: reservation,
      }
    })
    this.modalService.closed.subscribe(() => {
      this.service.getReservation(reservation.id).subscribe(res => {
        this.seasonal_reservations.find(res => res.id == reservation.id).reservation_items = res.reservation_items;
      });

    });
  }


}

