import { Component, OnInit } from '@angular/core';
import { Reservation, Season, SeasonElement } from '../reservations-table/reservation';
import { ReservationsService } from '../reservations-table/reservation.service';
import { DecimalPipe } from '@angular/common';
import * as _ from 'lodash';

@Component({
  selector: 'app-prices-table-view',
  templateUrl: './prices-table-view.component.html',
  styleUrls: ['./prices-table-view.component.scss'],
  providers: [DecimalPipe]
})
export class PricesTableViewComponent implements OnInit {

  constructor(public service: ReservationsService) { }

  seasons: Season[];

  async ngOnInit() {
    this.seasons = await this.service.getSeasonPrices().toPromise();
  }

}
