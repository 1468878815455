import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Facility } from 'src/app/auth';
import { AuthService } from 'src/app/auth.service';
import { ReservationWizardCompleteComponent } from 'src/app/modals/reservation-wizard-complete/reservation-wizard-complete.component';
import { Reservation, Seasonal } from 'src/app/reservations-table/reservation';
import { ReservationsService } from 'src/app/reservations-table/reservation.service';
import { SpinnerService } from 'src/app/spinner/spinner.service';
import { SeasonalModalFormComponent } from '../seasonal-modal-form/seasonal-modal-form.component';

@Component({
  selector: 'app-seasonal-page',
  templateUrl: './seasonal-page.component.html',
  styleUrls: ['./seasonal-page.component.scss']
})
export class SeasonalPageComponent implements OnInit {

  seasonals: Seasonal[];
  seasonals_reservations: Reservation[] = [];
  modalRef: MDBModalRef;
  facility: Facility = null;

  constructor(
    private spinnerService: SpinnerService,
    private service: ReservationsService,
    private modalService: MDBModalService,
    private authService: AuthService) { }

  async ngOnInit() {
    await this.fetchData();
    this.modalService.closed.subscribe(() => {
      if (this.modalService.config.data['seasonal_created'])
        this.fetchData();
    })
  }


  async fetchData() {
    this.spinnerService.startLoading();
    let facility = await this.authService.getFacilityRemote().toPromise();
    this.authService.resetFacility(facility);
    this.facility = this.authService.getFacility();
    this.seasonals_reservations = await this.service.getSeasonalReservations().toPromise();
    this.spinnerService.stopLoading();
  }

  openNewSeasonalForm() {
    this.modalRef = this.modalService.show(SeasonalModalFormComponent, {
      ignoreBackdropClick: true,
      class: 'modal-md',
    });
  }

  openReservationWizardModal() {
    let new_reservation: any = {
      place: null,
      place_object: null,
      area_object: null,
      facility: this.authService.getUserInfo().facility,
      make_seasonal: true,
    }
    this.modalRef = this.modalService.show(ReservationWizardCompleteComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      data: {
        reservation: new_reservation,
        step_selected: 1,
      }
    });
    this.modalService.closed.subscribe(() => {
      if (this.modalService.config.data['finished']) {
        this.fetchData();
      }
    });
  }


}
