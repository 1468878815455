import { Component, OnInit, ViewChild } from '@angular/core';
import { AreaMapComponent } from '../area-map/area-map.component';
import { HzDatepickerComponent } from '../hz-datepicker/hz-datepicker.component';
import { Facility } from '../auth';
import { AuthService } from '../auth.service';
import { TotalTableComponent } from '../total-table/total-table.component';


@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  @ViewChild(AreaMapComponent, { static: true }) map: AreaMapComponent;
  @ViewChild(TotalTableComponent, { static: true }) total: TotalTableComponent;
  @ViewChild(HzDatepickerComponent, { static: true }) picker: HzDatepickerComponent;

  constructor(public service: AuthService) { }

  facility: Facility = null;

  ngOnInit() {
    this.syncData()
  }

  async syncData() {
    let facility = await this.service.getFacilityRemote().toPromise();
    this.service.resetFacility(facility);
    this.facility = this.service.getFacility();
  }


  ngAfterViewInit() {
    this.map.date = this.picker.selectedDate;
    this.total.date = this.picker.selectedDate;
  }

  public changeDate(date: Date) {
    this.map.date = date;
    this.total.date = date;
  }

}
