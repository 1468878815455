import { DictToArrayPipe } from './dict_to_array';
import { Dictionary } from 'lodash';
export interface Deserializable {
  deserialize(input: any): this;
}

export class TransactionMetadata {
  area_name: string;
  reservation_id: number;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class Transaction implements Deserializable {
  id: string;
  payment_id: string;
  voucher: string;
  created: Date;
  checkin: Date;
  checkout: Date;
  date: Date;
  amount: number;
  fee: number;
  revenue: number;
  status: string;
  reservation_id: number;
  intent: Dictionary<string>;
  payed: boolean;
  canceled: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    this.date = this.created
    this.status = this.intent.status
    if (this.status != 'canceled') {
      this.canceled = false
    } else {
      this.canceled = true
    }
    return this;
  }
}


export class TransactionExt extends Transaction {
  checkin_formatted?: string;
  checkout_formatted?: string;
  date_formatted?: string;
}

export class TransactionsWrapper implements Deserializable {
  has_more: boolean;
  data: Transaction[];
  total: number;
  total_payed: number;

  deserialize(input: any) {
    Object.assign(this, input);
    if (this.data) {
      this.data = this.data.map(item => new Transaction().deserialize(item))
    }
    return this;
  }
}
