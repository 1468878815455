import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { UserInfo } from '../auth';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  constructor(public service: AuthService) { }

  user: UserInfo = null;
  @ViewChild('first_name', { static: true }) first_name:ElementRef;
  @ViewChild('last_name', { static: true }) last_name:ElementRef;
  @ViewChild('phone', { static: true }) phone:ElementRef;
  @ViewChild('email', { static: true }) email:ElementRef;

  ngOnInit() {
    this.syncData()
  }

  async syncData() {
    let user = await this.service.getUserInfoRemote().toPromise();
    this.service.resetUserInfo(user)
    this.user = user
  }

  saveConf() {
   //things
  }

  async putUser(){
    this.user.first_name = this.first_name.nativeElement.value
    this.user.last_name = this.last_name.nativeElement.value
    this.user.email = this.email.nativeElement.value
    this.user.phone = this.phone.nativeElement.value.toString()
    let user = await this.service.putUserInfoRemote(this.user).toPromise();
    this.service.setUserInfo(user);
    this.user = this.service.getUserInfo();
    alert("Account aggiornato!")
  }
  
  goToWhitelist() {
    window.open('/whitelist').focus()
  }

  async saveUser() {
    this.putUser()
  }

}
