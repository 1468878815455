import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const redirectUrl = route['_routerState']['url'];

    let userInfo = await this.authService.getUserInfoRemote().toPromise();



    if (this.authService.isLogged() && userInfo.state != 'ONB') {
      return true;
    }

    this.router.navigateByUrl('/login');
    // this.router.navigateByUrl(
    //   this.router.createUrlTree(
    //     ['/login'], {
    //       queryParams: {
    //         redirectUrl
    //       }
    //     }
    //   )
    // );

    return false;
  }
}
