import { AuthService } from './../auth.service';
import { DecimalPipe } from '@angular/common';
import { Component, QueryList, ViewChildren, OnInit, ViewChild, HostListener, ChangeDetectorRef, AfterViewInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { Reservation, ReservationExt } from './reservation';
import { ReservationsService } from './reservation.service';
import { NgbdSortableHeader, SortEvent } from './sortable.directive';
import { SpinnerService } from '../spinner/spinner.service';

import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Facility, PresenceInfo } from '../auth';
import * as moment from 'moment';
import { MDBModalRef, MDBModalService, MdbTableDirective, MdbTablePaginationComponent, ModalDirective } from 'angular-bootstrap-md';
import { ReservationDetailModalComponent } from '../modals/reservation-detail/reservation-detail-modal.component';


@Component(
  {
    selector: 'app-reservations-table',
    templateUrl: './reservations-table.component.html',
    styleUrls: ['./reservations-table.component.scss'],
    providers: [DecimalPipe, ReservationsService, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
  })
export class ReservationsTableComponent implements OnInit, AfterViewInit {
  effective_presence$: Observable<number>;
  facility: Facility = null;
  presence: PresenceInfo = null;
  previous: string;
  number_people_input: number;
  selected_reservation: Reservation;

  private _reservations: ReservationExt[] = [];
  public get reservations(): ReservationExt[] {
    return this._reservations;
  }
  public set reservations(value: ReservationExt[]) {
    if (value) {
      value.forEach(r => {
        r.checkin_formatted = moment(r.checkin).format('DD/MM/YYYY');
        r.checkout_formatted = moment(r.checkout).format('DD/MM/YYYY');
      });
      this._reservations = value;
      this.mdbTable.setDataSource(this._reservations);
      this.previous = this.mdbTable.getDataSource();
      this.calculatePages();
    }
  }


  searchText: string = '';
  headElements = [
    { label: 'ID', sort: 'id' },
    { label: 'CLIENTE', sort: 'user_copy.first_name' },
    { label: 'VOUCHER', sort: 'voucher' },
    { label: 'EMAIL', sort: 'user_copy.email' },
    { label: 'DATA', sort: 'checkin' },
    { label: '€', sort: 'total' },
    { label: 'POSTO', sort: 'hasPlacesToBeAssigned' },
    { label: 'CHECK-IN', sort: 'checked_in' },
    { label: 'DETTAGLIO', }
  ];

  number_pages: number;
  pages: number[];
  max_element_per_page: number = 10;


  private _reload: boolean;
  public get reload(): boolean {
    return this._reload;
  }
  @Input()
  public set reload(value: boolean) {
    this._reload = value;
    this.fetchData();
  }


  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  model: Date;

  constructor(
    public service: ReservationsService,
    public spinnerService: SpinnerService,
    public authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private modalService: MDBModalService,
  ) {
  }

  @HostListener('input') oninput() {
    this.searchItems();
  }



  ngOnInit() {

  }


  ngAfterViewInit() {
    this.fetchData();
  }

  async fetchData() {
    this.spinnerService.startLoading();
    this.reservations = await this.service.getReservations().toPromise();
    this.spinnerService.stopLoading();
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.max_element_per_page);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
      this.mdbTable.setDataSource(prev);
      this.reservations = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.reservations = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
      this.mdbTable.setDataSource(prev);
    }

  }

  calculatePages() {
    this.number_pages = Math.floor(this.reservations.length / this.mdbTablePagination.maxVisibleItems);
    let mod = Math.floor(this.reservations.length % this.mdbTablePagination.maxVisibleItems);
    if (mod > 0 || this.number_pages == 0)
      this.number_pages++;
    this.pages = [];
    for (let i = 1; i <= this.number_pages; i++) {
      this.pages.push(i);
    }
  }

  goToPage(page: number) {
    let diff = page - this.mdbTablePagination.activePageNumber;
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        this.mdbTablePagination.nextPage();
      }
    }
    if (diff < 0) {
      for (let i = 0; i < Math.abs(diff); i++) {
        this.mdbTablePagination.previousPage();
      }
    }

  }


  goToReservationDetail(reservation: Reservation) {
    this.modalService.show(ReservationDetailModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg modal-detail',
      data: {
        reservation: reservation,
      }
    })
  }


  async doCheckin(checkInModal: ModalDirective) {
    if (!this.number_people_input || this.number_people_input == 0)
      return;
    this.spinnerService.startLoading();
    const updatedReservation = await this.service.getReservation(this.selected_reservation.id).toPromise();
    updatedReservation.checked_in = true;
    updatedReservation.presence = this.number_people_input;
    this.selected_reservation = await this.service.updateReservation(updatedReservation).toPromise();
    this.number_people_input = null;
    checkInModal.hide();
    this.spinnerService.stopLoading();
    this.fetchData();
  }

  openCheckInModal(reservation: Reservation, checkInModal: ModalDirective) {
    this.selected_reservation = reservation;
    checkInModal.show();
  }

}
