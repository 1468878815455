import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { NewReservation, Seasonal } from 'src/app/reservations-table/reservation';
import { ReservationsService } from 'src/app/reservations-table/reservation.service';
import { SpinnerService } from 'src/app/spinner/spinner.service';

@Component({
  selector: 'app-reservation-wizard-fifth',
  templateUrl: './reservation-wizard-fifth.component.html',
  styleUrls: ['./reservation-wizard-fifth.component.scss']
})
export class ReservationWizardFifthComponent implements OnInit {

  @Input() reservation: any;

  @Output() previous_step = new EventEmitter<any>();
  @Output() next_step = new EventEmitter<any>();

  message: string = "";
  error: boolean = false;

  new_reservation: NewReservation;

  constructor(
    private service: ReservationsService,
    private spinnerService: SpinnerService,
    private authService: AuthService,) { }

  async ngOnInit() {
    this.spinnerService.startLoading();
    this.new_reservation = new NewReservation();
    Object.assign(this.new_reservation, this.reservation);
    if (this.reservation.make_seasonal) {
      /*  let seasonal: Seasonal = {
         id: null,
         email: this.new_reservation.email,
         first_name: this.new_reservation.name,
         last_name: this.new_reservation.surname,
         phone: this.reservation.phone,
         role: "SE",
         state: "CON",
         seasonal_facilities: [this.authService.getUserInfo().facility]
       }
       try {
         await this.service.makeSeasonal(seasonal).toPromise();
       } catch (e) {
         console.log(e);
         return;
       } */
      this.new_reservation.seasonal = true;
    }
    this.service.makeReservation(this.new_reservation).subscribe(ret => {
      this.error = false;
      this.message = "Prenotazione effettuata";
    },
      error => {
        this.error = true;
        this.message = "Si è verificato un errore";
        if (error.error) {
          this.message = "";
          error.error.forEach(e => {
            this.message += e;
          })
        }
      }
    )
    this.spinnerService.stopLoading();
  }

  goToPreviousStep() {
    this.previous_step.emit(this.reservation);
  }

  goToNextStep() {
    this.next_step.emit(this.reservation);
  }


}
