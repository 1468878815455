import { Component, OnInit, Input } from '@angular/core';
import { MapPlace, Reservation, Period, NewReservation, SeasonElement } from '../reservations-table/reservation';
import { ReservationsService } from '../reservations-table/reservation.service';
import { SpinnerService } from '../spinner/spinner.service';
import * as _ from 'lodash';
import * as moment from 'moment';

class SeasonElementWrapper {
  element: SeasonElement;
  quantity: number;
}

@Component({
  selector: 'app-form-new-reservation',
  templateUrl: './form-new-reservation.component.html',
  styleUrls: ['./form-new-reservation.component.scss']
})
export class FormNewReservationComponent implements OnInit {

  @Input() _selectedPlace: MapPlace = null;
  @Input() _facilityID: number = null;
  @Input() _selectedMorning: Reservation = null;
  @Input() _selectedAfternoon: Reservation = null;
  @Input() _selectedDay: Reservation = null;
  @Input() _date = new Date();
  @Input() _checkin :any ;
  @Input() _presence: number = null;

  _periodToReserve: Period = Period.DAY;

  public elementsQuantity: SeasonElementWrapper[] = null;
  checked_in: boolean = false;

  constructor(private service: ReservationsService, private spinnerService: SpinnerService) { }

  ngOnInit() {

  }

  @Input() set periodToReserve(period: Period) {
    this._periodToReserve = period;
    this.spinnerService.startLoading();
    let x = this.updateElements();
    x.then(_x => {
      this.spinnerService.stopLoading();
    })
  }

  get periodToReserve() {
    return this._periodToReserve;
  }

  async updateElements() {
    this._date = new Date();
    let years  = moment(this._date).year();
    let months = moment(this._date).month() + 1;
    let days   = moment(this._date).date();
    let date_ : string = years + "-" + months + "-" + days;
    this.elementsQuantity = [];
    let elements = await this.service.getElements(date_, date_, this._periodToReserve).toPromise();
    for(let element of elements) {
        this.elementsQuantity.push({element: element, quantity:0});
    }
  }

  async onChangePeriod(period: Period) {
    this._periodToReserve = period;
    this.spinnerService.startLoading();
    await this.updateElements();
    this.spinnerService.stopLoading();
  }

  public async makeReservation() {
    let newReservation = new NewReservation();
    newReservation.checkin = this._date.toDateString();
    newReservation.place = this._selectedPlace.id;
    newReservation.checkout = this._date.toDateString();
    newReservation.period = this._periodToReserve;
    newReservation.offline = true;
    newReservation.checked_in = this.checked_in;
    newReservation.presence = this._presence;
    newReservation.elements = [];
    for (let element of this.elementsQuantity) {
      if (element.quantity > 0) {
        newReservation.elements.push({ id: element.element.id, quantity: element.quantity });
        element.quantity = 0;
      }
    }
    return await this.service.makeReservation(newReservation).toPromise();
  }

  public async makeNoPlaceReservation() {
    let newReservation = new NewReservation();  
    let date_ = new Date(this._checkin.year, (this._checkin.month -1 ), this._checkin.day,);
    newReservation.facility = this._facilityID;
    newReservation.checkin = date_.toDateString();
    newReservation.checkout = date_.toDateString();
    newReservation.period = this._periodToReserve;
    newReservation.offline = true;
    newReservation.checked_in = this.checked_in;
    newReservation.presence = this._presence;
    newReservation.elements = [];
    // for (let element of this.elementsQuantity) {
    //   if (element.quantity > 0) {
    //     newReservation.elements.push({ id: element.element.id, quantity: element.quantity });
    //     element.quantity = 0;
    //   }
    // }
    return await this.service.makeReservation(newReservation).toPromise();
  }

}
