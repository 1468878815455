import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-backbutton',
  templateUrl: './backbutton.component.html',
  styleUrls: ['./backbutton.component.scss']
})
export class BackbuttonComponent implements OnInit {

  constructor(private _location: Location) { }

  ngOnInit() {
  }

  goBack(): void {
    this._location.back();
  }
}
