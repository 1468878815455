import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Facility } from '../auth';

@Component({
  selector: 'app-listino',
  templateUrl: './listino.component.html',
  styleUrls: ['./listino.component.scss']
})
export class ListinoComponent implements OnInit {

  constructor(public service: AuthService) { }

  facility: Facility = null;

  ngOnInit() {
    this.syncData()
  }
  
  async syncData(){
    let facility = await this.service.getFacilityRemote().toPromise();
    this.service.resetFacility(facility);
    this.facility = this.service.getFacility();
  }

}
