import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ReservationsService } from '../reservations-table/reservation.service';
import { Reservation, Period, ReservationItemExt, AssignPlaceRequest } from '../reservations-table/reservation';
import { NgbDatepickerConfig, NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { AssignMapComponent } from '../assign-map/assign-map.component';
import * as moment from 'moment';
import * as _ from 'lodash';
import { SpinnerService } from '../spinner/spinner.service';

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.scss'],
  providers: [DecimalPipe, NgbDatepickerConfig]
})
export class ReservationDetailComponent implements AfterViewInit {

  reservation: Reservation;
  hoveredDate: NgbDate = null;
  fromDate: NgbDate = null;
  toDate: NgbDate = null;

  loading_checkin: boolean = false;

  _reservationItem: ReservationItemExt = null;

  @ViewChild('openModalButton', { static: true }) openModalButton: ElementRef;
  @ViewChild('openModalMap', { static: true }) openModalButton2: ElementRef;
  @ViewChild(AssignMapComponent, { static: true }) map: AssignMapComponent;

  toNgbDateStruct(date: Date): NgbDateStruct {
    let mom = moment(date);
    let ngbDateStruct = { day: mom.date(), month: mom.month() + 1, year: mom.year() };
    return ngbDateStruct;
  }

  getNgbCheckin(): NgbDateStruct {
    return this.toNgbDateStruct(this.reservation.checkin);
  }

  getNgbCheckout(): NgbDateStruct {
    return this.toNgbDateStruct(this.reservation.checkout);
  }

  // isReserved = (date: NgbDate) => {
  //   for (let sharedDate of this.reservation.shared_dates) {
  //     var checkin = NgbDate.from(sharedDate.checkin);
  //     var checkout = NgbDate.from(sharedDate.checkout);

  //     var after: boolean = date.after(checkin);
  //     var before: boolean = date.before(checkout);
  //     var equalCheckin: boolean = date.equals(checkin);
  //     var equalCheckout: boolean = date.equals(checkout);

  //     if ((after || equalCheckin) && (before || equalCheckout)) return true;
  //   }
  //   return false;
  // };

  // isAvaiable = (date: NgbDate) => {
  //   var isReserved = this.isReserved(date);

  //   var checkin = NgbDate.from(this.toNgbDateStruct(this.reservation.checkin));
  //   var checkout = NgbDate.from(this.toNgbDateStruct(this.reservation.checkout));

  //   var after: boolean = date.after(checkin);
  //   var before: boolean = date.before(checkout);
  //   var equalCheckin: boolean = date.equals(checkin);
  //   var equalCheckout: boolean = date.equals(checkout);

  //   return (!isReserved && (after || equalCheckin) && (before || equalCheckout));
  // };

  // isOutOfRange = (date: NgbDate) => {
  //   var checkin = NgbDate.from(this.toNgbDateStruct(this.reservation.checkin));
  //   var checkout = NgbDate.from(this.toNgbDateStruct(this.reservation.checkout));
  //   return date.before(checkin) || date.after(checkout);
  // };

  constructor(private route: ActivatedRoute, public service: ReservationsService, private calendar: NgbCalendar, private _location: Location, private spinnerService: SpinnerService) { }

  ngAfterViewInit() {
    this.route.paramMap.subscribe(params =>
      this.service.getReservation(+params.get('reservation_id')).subscribe(reservation_ => {
        this.reservation = reservation_;
        this.reservation.buildSummary();
        this.map.checkin = this.reservation.checkin;
        this.map.checkout = this.reservation.checkout;
        this.map.period = this.reservation.period;
      })
    )
  }

  // deleteSharedDate(index: number): void {
  //   this.reservation.shared_dates.splice(index, 1);
  // }

  async assignPlace() {
    if (this.map.selectedPlace == null) return;
    let assignRequest = new AssignPlaceRequest();
    assignRequest.place = this.map.selectedPlace.id;
    assignRequest.item = this._reservationItem.id;
    this.spinnerService.startLoading();
    this.reservation = await this.service.assignPlace(assignRequest, this.reservation).toPromise();
    this.reservation.buildSummary();
    this.openModalButton2.nativeElement.click();
    this.spinnerService.stopLoading();
  }

  canSave(): boolean {
    return (this.fromDate != null && this.toDate != null);
  }

  showCheckinSpinner() {
    this.loading_checkin = true;
  }

  hideCheckinSpinner() {
    this.loading_checkin = false;
  }

  async deleteReservation() {
    this.showCheckinSpinner();
    let result = await this.service.deleteReservation(this.reservation).toPromise();
    this.hideCheckinSpinner();
    // this.openModalButton.nativeElement.hide();
    this._location.back();
  }

  openCancelModal() {
    this.openModalButton.nativeElement.click();
  }

  openMapModal(item: ReservationItemExt) {
    this._reservationItem = item;
    this.openModalButton2.nativeElement.click();
  }

  async doCheckin() {
    let updatedReservation = _.cloneDeep(this.reservation);
    updatedReservation.checked_in = true;
    this.showCheckinSpinner();
    this.reservation = await this.service.updateReservation(updatedReservation).toPromise();
    this.reservation.buildSummary();
    this.hideCheckinSpinner();
  }

  // save(): void {
  //   if (this.fromDate && this.toDate) {
  //     this.reservation.shared_dates.push({ checkin: this.fromDate, checkout: this.toDate });
  //     this.fromDate = null;
  //     this.toDate = null;
  //   }
  // }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  getElementImage(item: ReservationItemExt): string {
    if (item.element != null) {
      return "assets/img/" + item.element.keyword + ".png";
    }
    else {
      return "assets/img/assegnato.png";
    }
  }

  getElementName(item: ReservationItemExt): string {
    if (item.element != null) {
      return item.element.name;
    }
    else {
      return "Libero";
    }
  }

  getElementQuantity(item: ReservationItemExt): string {
    if (item.element != null) {
      return String(item.quantity);
    }
    else {
      return "";
    }
  }

}
