import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { Facility } from '../auth';
import { FormNewReservationComponent } from '../form-new-reservation/form-new-reservation.component';
import { Period, Reservation } from '../reservations-table/reservation';
import { SpinnerService } from '../spinner/spinner.service';
import * as moment from 'moment';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ReservationWizardCompleteComponent } from '../modals/reservation-wizard-complete/reservation-wizard-complete.component';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent implements OnInit {

  constructor(
    public service: AuthService,
    private spinnerService: SpinnerService,
    private modalService: MDBModalService,
    private authService: AuthService
  ) { }

  @ViewChild('mapReserveButton', { static: true }) mapReserveButton: ElementRef;
  @ViewChild(FormNewReservationComponent, { static: false }) formNewReservation: FormNewReservationComponent;

  facility: Facility = null;
  loading_checkin: boolean = false;

  _loading: boolean = false;
  _date: Date = null;
  _period: Period = Period.MOR;

  _selectedMorning: Reservation = null;
  _selectedAfternoon: Reservation = null;
  _selectedDay: Reservation = null;
  _checkinReservation: Reservation = null;
  _periodToReserve: Period = null;

  modalRef: MDBModalRef;

  reload_table: boolean = false;

  ngOnInit() {
    this.syncData();
    this.modalService.closed.subscribe(() => {
      if (this.modalService.config.data['finished'])
        this.reload_table = true;
    })
  }

  async syncData() {
    let facility = await this.service.getFacilityRemote().toPromise();
    this.service.resetFacility(facility);
    this.facility = this.service.getFacility();
  }

  public openReservationModal() {
    let new_reservation: any = {
      facility: this.authService.getUserInfo().facility,
    }
    this.modalRef = this.modalService.show(ReservationWizardCompleteComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      data: {
        reservation: new_reservation,
        step_selected: 1
      }
    });
  }

  public closeReservationModal() {
    this.mapReserveButton.nativeElement.click();
    // this.mapDetailButton.nativeElement.click();
    // this.mapDetailVisible = !this.mapDetailVisible;
  }

  public async doReservation() {
    // let newReservation = new NewReservation();
    // newReservation.checkin = this._date;
    // newReservation.place = this._selectedPlace.id;
    // newReservation.checkout = this._date;
    // newReservation.period = this._periodToReserve;
    // newReservation.offline = true;

    this.closeReservationModal();
    this.showCheckinSpinner();
    this._loading = true;

    const reservation = await this.formNewReservation.makeNoPlaceReservation();

    // let reservation = await this.service.makeReservation(newReservation).toPromise();
    if (reservation.period == Period.DAY) {
      this._selectedDay = reservation;
    } else if (reservation.period == Period.MOR) {
      this._selectedMorning = reservation;
    } else if (reservation.period == Period.AFT) {
      this._selectedAfternoon = reservation;
    }

    await this.refreshView();
    // let years = moment(this.date).year();
    // let months = moment(this.date).month() + 1;
    // let days = moment(this.date).date();

    // let date_: string = years + "-" + months + "-" + days;
    // this._places = await this.service.getPlacesWithDate(this.area, date_).toPromise();
    // this._places.places = _.sortBy(this._places.places, ['row', 'col']);
    this.hideCheckinSpinner();
    this._loading = false;
  }

  showCheckinSpinner() {
    // this.loading_checkin = true;
    this.spinnerService.startLoading();
  }

  hideCheckinSpinner() {
    // this.loading_checkin = false;
    this.spinnerService.stopLoading();
  }

  async refreshView() {
    window.location.reload();
  }
}
