import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import * as moment from 'moment';
import { DaterangepickerComponent, DaterangepickerDirective, LocaleConfig } from 'ngx-daterangepicker-material';
import { Reservation, Share, ShareExtended } from 'src/app/reservations-table/reservation';
import { ReservationsService } from 'src/app/reservations-table/reservation.service';
import { SpinnerService } from 'src/app/spinner/spinner.service';
moment.locale('it')

@Component({
  selector: 'app-reservation-detail-modal',
  templateUrl: './reservation-detail-modal.component.html',
  styleUrls: ['./reservation-detail-modal.component.scss']
})
export class ReservationDetailModalComponent implements OnInit {

  @Input() reservation: Reservation;


  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private spinnerService: SpinnerService,
    private service: ReservationsService) { }

  ngOnInit() {
  }


  closeModal() {
    this.modalRef.hide();
  }


}
