import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { Facility, UserInfo } from '../auth';

@Component({
  selector: 'app-beach',
  templateUrl: './beach.component.html',
  styleUrls: ['./beach.component.scss']
})
export class BeachComponent implements OnInit {

  constructor(public service: AuthService) { }

  facility: Facility = null;
  user: UserInfo = null;
  @ViewChild('address', { static: true }) address: ElementRef;
  @ViewChild('name', { static: true }) name: ElementRef;
  @ViewChild('phone', { static: true }) phone: ElementRef;
  @ViewChild('desc', { static: true }) desc: ElementRef;
  @ViewChild('email', { static: true }) email: ElementRef;

  ngOnInit() {
    this.syncData()
  }

  async syncData() {
    let user = await this.service.getUserInfoRemote().toPromise();
    this.service.resetUserInfo(user)
    this.user = user
    let facility = await this.service.getFacilityRemote().toPromise();
    this.service.resetFacility(facility);
    this.facility = this.service.getFacility();
  }

  saveConf() {
    //things
  }

  async getFacility() {
    let facility = await this.service.getFacilityRemote().toPromise();
    this.service.resetFacility(facility);
    this.facility = this.service.getFacility();
  }

  async enableFacility(state: boolean) {
    this.facility.enabled = state
    let facility = await this.service.toggleFacilityRemote().toPromise();
    this.service.setFacility(facility);
    this.facility = this.service.getFacility();
  }

  async putFacility() {
    this.facility.name = this.name.nativeElement.value
    this.facility.address = this.address.nativeElement.value
    this.facility.email = this.email.nativeElement.value
    this.facility.description = this.desc.nativeElement.value
    this.facility.phone = this.phone.nativeElement.value.toString()
    let facility = await this.service.putFacilityRemote(this.facility).toPromise();
    this.service.setFacility(facility);
    this.facility = this.service.getFacility();
    alert("Stabilimento aggiornato!")
  }

  goToWhitelist() {
    window.open('/whitelist').focus()
  }

  async saveFacility() {
    this.putFacility()
  }

  async makeRentable() {
    this.enableFacility(true)
  }

  async makeUnRentable() {
    this.enableFacility(false)
  }

}
