import { Component, OnInit } from '@angular/core';
import { ReservationsService } from '../reservations-table/reservation.service';
import { MapPlaces, MapPlace, Area, Level } from '../reservations-table/reservation';
import { Period } from '../reservations-table/reservation';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-assign-map',
  templateUrl: './assign-map.component.html',
  styleUrls: ['./assign-map.component.scss']
})
export class AssignMapComponent implements OnInit {

  constructor(public service: ReservationsService) { }

  ngOnInit() {
    this.getAreas();
  }

  _loading: boolean = false;
  _places: MapPlaces = null;
  _areas: Area[] = null;
  _checkin: Date = null;
  _checkout: Date = null;
  _period: Period = null;
  _selectedPlace: MapPlace = null;

  onChangeEvent(newValue: number) {
    this.area = newValue
  }

  set selectedPlace(place: MapPlace) {
    if (place.reservation.length) return;
    this._selectedPlace = place;
  }

  get selectedPlace() {
    return this._selectedPlace;
  }

  set checkin(date: Date) {
    this._checkin = date;
    this.getPlaces();
  }

  get checkin() {
    return this._checkin;
  }

  set checkout(date: Date) {
    this._checkout = date;
    this.getPlaces();
  }

  get checkout() {
    return this._checkout;
  }

  set period(period: Period) {
    this._period = period;
    this.getPlaces();
  }

  get period() {
    return this._period;
  }

  set area(area: number) {
    this.service.setCurrentMapArea(area);
    this.getPlaces();
  }

  get area() {
    return this.service.getCurrentMapArea();
  }

  counter(i: number) {
    return new Array(i);
  }

  showSpinner() {
    this._loading = true;
  }

  hideSpinner() {
    this._loading = false;
  }

  getDateString(date: Date): string {
    let years = moment(date).year();
    let months = moment(date).month() + 1;
    let days = moment(date).date();
    let date_: string = years + "-" + months + "-" + days;
    return date_;
  }

  getPlace(row: number, col: number): MapPlace {
    return this._places.places[row * this._places.col + col];
  }

  async getPlaces() {
    if (this.area == null || this._checkin == null) return;
    this.showSpinner();
    await this.refreshMap();
    this.hideSpinner();
  }

  async getAreas() {
    this.showSpinner();
    this._areas = await this.service.getAreas().toPromise();
    if (this.area == null && this._areas.length > 0) {
      this.area = this._areas[0].id;
    }
    this.hideSpinner();
  }

  async refreshMap() {
    let checkin_ = this.getDateString(this.checkin);
    let checkout_ = this.getDateString(this.checkout);
    let period_ = this._period;
    this._places = await this.service.getPlacesWithRangeAndPeriod(this.area, checkin_, checkout_, period_).toPromise();
    this._places.places = _.sortBy(this._places.places, ['row', 'col']);
  }

  getMorningImage(place: MapPlace): string {
    if (place.reservation == null) {
      return "assets/img/mattina_vendibile.svg";
    }
    for (let reservation of place.reservation) {
      if (reservation.period == Period.MOR) {
        if (reservation.offline == false && !reservation.checked_in) {
          return "assets/img/mattina_online.svg";
        } else if (reservation.offline == false && reservation.checked_in) {
          return "assets/img/mattina_checkin_online.svg";
        }
        else if (reservation.offline == true && !reservation.checked_in) {
          return "assets/img/mattina_offline.svg";
        } else if (reservation.offline == true && reservation.checked_in) {
          return "assets/img/mattina_checkin_offline.svg";
        }
      }
      else if (reservation.period == Period.DAY) {
        if (reservation.offline == false && !reservation.checked_in) {
          return "assets/img/mattina_online-pieno.svg";
        } else if (reservation.offline == false && reservation.checked_in) {
          return "assets/img/mattina_checkin_online-pieno.svg";
        }
        else if (reservation.offline == true && !reservation.checked_in) {
          return "assets/img/mattina_offline-pieno.svg";
        } else if (reservation.offline == true && reservation.checked_in) {
          return "assets/img/mattina_checkin_offline-pieno.svg";
        }
      }
    }
    return "assets/img/mattina_vendibile.svg";
  }

  getAfternoonImage(place: MapPlace): string {
    if (place.reservation == null) {
      return "assets/img/sera_vendibile.svg";
    }
    for (let reservation of place.reservation) {
      if (reservation.period == Period.AFT) {
        if (reservation.offline == false && !reservation.checked_in) {
          return "assets/img/sera_online.svg";
        } else if (reservation.offline == false && reservation.checked_in) {
          return "assets/img/sera_checkin_online.svg";
        }
        else if (reservation.offline == true && !reservation.checked_in) {
          return "assets/img/sera_offline.svg";
        } else if (reservation.offline == true && reservation.checked_in) {
          return "assets/img/sera_checkin_offline.svg";
        }
      }
      else if (reservation.period == Period.DAY) {
        if (reservation.offline == false && !reservation.checked_in) {
          return "assets/img/sera_online-pieno.svg";
        } else if (reservation.offline == false && reservation.checked_in) {
          return "assets/img/sera_checkin_online-pieno.svg";
        }
        else if (reservation.offline == true && !reservation.checked_in) {
          return "assets/img/sera_offline-pieno.svg";
        } else if (reservation.offline == true && reservation.checked_in) {
          return "assets/img/sera_checkin_offline-pieno.svg";
        }
      }
    }
    return "assets/img/sera_vendibile.svg";
  }

  getShadowElement(place: MapPlace): string {
    if (place.shadow_element == null) {
      return "assets/img/map_free2.svg";
    }
    else {
      return "assets/img/map_" + place.shadow_element.keyword + ".svg";
    }
  }

  getPlaceLevelImage(place: MapPlace): string {
    if (place.level === Level.NRM) {
      return "assets/img/livello-basic.svg";
    }
    else if (place.level === Level.SLV) {
      return "./../assets/img/livello-silver.svg";
    }
    else if (place.level === Level.GLD) {
      return "./../assets/img/livello-gold.svg";
    }
  }

  getPlaceLevelImageLarge(place: MapPlace): string {
    if (place.level === Level.NRM) {
      return "assets/img/stella_basic.svg";
    }
    else if (place.level === Level.SLV) {
      return "./../assets/img/stella_silver.svg";
    }
    else if (place.level === Level.GLD) {
      return "./../assets/img/stella_golder.svg";
    }
  }

  getPlaceLevelText(place: MapPlace): string {
    if (place.level === Level.NRM) {
      return "Basic";
    }
    else if (place.level === Level.SLV) {
      return "Silver";
    }
    else if (place.level === Level.GLD) {
      return "Gold";
    }
  }

  getElementImage(place: MapPlace): string {
    if (place.shadow_element != null) {
      return "assets/img/" + place.shadow_element.keyword + ".png";
    }
    else {
      return "assets/img/libero.svg";
    }
  }

  getElementText(place: MapPlace): string {
    if (place.shadow_element != null) {
      return place.shadow_element.name;
    }
    else {
      return "Libero";
    }
  }

}
