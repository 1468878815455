import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfo } from '../auth';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  current_step: number = 1;
  old_password: string;
  new_password: string;
  new_password_confirm: string;
  error_message: string;

  show_old_password: boolean;
  show_new_password: boolean;
  show_new_password_confirm: boolean;

  terms_accepted: boolean;

  user: UserInfo = null;

  terms: string;
  terms_shorted: string;

  constructor(public service: AuthService, private router: Router) { }

  ngOnInit() {
    this.syncData();
  }

  async syncData() {
    let user = await this.service.getUserInfoRemote().toPromise();
    this.service.resetUserInfo(user);
    this.user = user;
    this.terms = await this.service.getTerms().toPromise();
    this.terms_shorted = this.getTermsShorted();
  }

  async submitNewPassword() {
    try {
      if (this.new_password != this.new_password_confirm)
        this.error_message = 'Le due password non sono uguali';
      else {
        this.user.password = this.new_password;
        let user = await this.service.putUserInfoRemote(this.user).toPromise();
        this.service.setUserInfo(user);
        this.user = this.service.getUserInfo();
        this.error_message = null;
        this.current_step = 3;
      }
    } catch (e) {
      console.log(e);
      this.error_message = 'Errore server';
    }

  }

  getTermsShorted() {
    let endParagraph = this.terms.indexOf('</p>')
    if (endParagraph)
      return this.terms.substring(0, endParagraph);
    else
      this.terms;
  }

  goToLogin() {
    this.service.logout();
    this.router.navigateByUrl('/login');
  }

}
