import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';


import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth-interceptor'

// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

import { MomentModule } from 'ngx-moment';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MainHeaderComponent } from './main-header/main-header.component';
import { AccountComponent } from './account/account.component';
import { WhitelistComponent } from './whitelist/whitelist.component';
import { SupportComponent } from './support/support.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { OverviewComponent } from './overview/overview.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { ExperiencesComponent } from './experiences/experiences.component';
import { WalletComponent } from './wallet/wallet.component';
import { BeachComponent } from './beach/beach.component';
import { ReservationsTableComponent } from './reservations-table/reservations-table.component';
import { NgbdSortableHeader } from './reservations-table/sortable.directive';
import { ReservationDetailComponent } from './reservation-detail/reservation-detail.component';
import { DictToArrayPipe } from './dict_to_array';
import { HzDatepickerComponent } from './hz-datepicker/hz-datepicker.component';
import { FooterComponent } from './footer/footer.component';
import { BackbuttonComponent } from './backbutton/backbutton.component';
import { AreaMapComponent } from './area-map/area-map.component';
import { WalletTableComponent } from './wallet-table/wallet-table.component';
import { ListinoComponent } from './listino/listino.component';
import { PricesTableComponent } from './prices-table/prices-table.component';
import { FormNewReservationComponent } from './form-new-reservation/form-new-reservation.component';
import { ReservationsService } from './reservations-table/reservation.service';
import { DecimalPipe } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { PricesTableViewComponent } from './prices-table-view/prices-table-view.component';
import { AuthService } from './auth.service';
import { SpinnerComponent } from './spinner/spinner.component'
import { SpinnerService } from './spinner/spinner.service';
import { AssignMapComponent } from './assign-map/assign-map.component';
import { MatInputModule } from '@angular/material/input';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { WalletModalComponent } from './wallet-modal/wallet-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { ClosureDateComponent } from './closure-date/closure-date.component';
import { TotalTableComponent } from './total-table/total-table.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ReservationWizardFirstComponent } from './modals/reservation-wizard-first/reservation-wizard-first.component';
import { ReservationWizardSecondComponent } from './modals/reservation-wizard-second/reservation-wizard-second.component';
import { ReservationWizardThirdComponent } from './modals/reservation-wizard-third/reservation-wizard-third.component';
import { ReservationWizardCompleteComponent } from './modals/reservation-wizard-complete/reservation-wizard-complete.component';
import { ReservationWizardFourthComponent } from './modals/reservation-wizard-fourth/reservation-wizard-fourth.component';
import { ReservationWizardFifthComponent } from './modals/reservation-wizard-fifth/reservation-wizard-fifth.component';
import { SeasonalTableComponent } from './seasonal/seasonal-table/seasonal-table.component';
import { SeasonalPageComponent } from './seasonal/seasonal-page/seasonal-page.component';
import { SeasonalModalFormComponent } from './seasonal/seasonal-modal-form/seasonal-modal-form.component';
import { ReservationDetailModalComponent } from './modals/reservation-detail/reservation-detail-modal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReservationWizardPlaceSelectionComponent } from './modals/reservation-wizard-place-selection/reservation-wizard-place-selection.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { ReservationShareComponent } from './modals/reservation-share/reservation-share.component';

@NgModule({
  declarations: [
    AppComponent,
    MainHeaderComponent,
    AccountComponent,
    SupportComponent,
    NotificationsComponent,
    MainMenuComponent,
    OverviewComponent,
    ReservationsComponent,
    ExperiencesComponent,
    WalletComponent,
    BeachComponent,
    ReservationsTableComponent,
    NgbdSortableHeader,
    ReservationDetailComponent,
    DictToArrayPipe,
    HzDatepickerComponent,
    FooterComponent,
    BackbuttonComponent,
    AreaMapComponent,
    WalletTableComponent,
    ListinoComponent,
    PricesTableComponent,
    FormNewReservationComponent,
    LoginComponent,
    PricesTableViewComponent,
    SpinnerComponent,
    AssignMapComponent,
    WhitelistComponent,
    WalletModalComponent,
    ClosureDateComponent,
    TotalTableComponent,
    ReservationWizardFirstComponent,
    ReservationWizardSecondComponent,
    ReservationWizardThirdComponent,
    ReservationWizardCompleteComponent,
    ReservationWizardFourthComponent,
    ReservationWizardFifthComponent,
    SeasonalTableComponent,
    SeasonalPageComponent,
    SeasonalModalFormComponent,
    ReservationDetailModalComponent,
    DashboardComponent,
    ReservationWizardPlaceSelectionComponent,
    OnboardingComponent,
    ReservationShareComponent
  ],
  imports: [
    AutocompleteLibModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    MomentModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatInputModule,
    MDBBootstrapModule.forRoot(),
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [ReservationsService, DecimalPipe, AuthService, SpinnerService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent],
  entryComponents: [
    WalletModalComponent,
    ReservationWizardFirstComponent,
    ReservationWizardSecondComponent,
    ReservationWizardThirdComponent,
    ReservationWizardCompleteComponent,
    ReservationWizardFourthComponent,
    ReservationWizardFifthComponent,
    SeasonalModalFormComponent,
    ReservationDetailModalComponent,
    ReservationShareComponent
  ]
})
export class AppModule { }
registerLocaleData(localeIt);
