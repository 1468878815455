
import { Injectable, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { Transaction, TransactionsWrapper } from './transactions';
import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap, map, filter, mapTo } from 'rxjs/operators';
import { SortDirection } from './reservations-table/sortable.directive';
import { AuthService } from './auth.service';

import * as moment from 'moment';
import * as _ from 'lodash';
import { BASE_PATH } from 'src/environments/environment';

interface SearchResult {
  transactions: Transaction[];
  total: number;
  total_payed: number;
  amount: number;
  hasMore: boolean;
}

interface State {
  searchTerm: string;
  sortColumn: string;
  sortDirection: SortDirection;
  fromDate: Date;
  toDate: Date;
  firstItem: Transaction;
  lastItem: Transaction;
  prevOrNext: boolean;
  payed: boolean;
  hasPrev: boolean;
  hasNext: boolean;
}

function compare(v1, v2) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(reservations: Transaction[], column: string, direction: string): Transaction[] {
  if (direction === '') {
    return reservations;
  } else {
    return [...reservations].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(reservation: Transaction, term: string) {
  return reservation.voucher.includes(term);
}

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  private transactionsUrl = BASE_PATH + '/api/transaction/';  // URL to web api

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _transactions$ = new BehaviorSubject<Transaction[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _total_payed$ = new BehaviorSubject<number>(0);
  private _amount$ = new BehaviorSubject<number>(0);

  private _cachedCopy: Transaction[] = [];

  private _state: State = {
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
    fromDate: null,
    toDate: null,
    firstItem: null,
    lastItem: null,
    prevOrNext: null,
    payed: false,
    hasPrev: false,
    hasNext: true
  };

  constructor(private authService: AuthService, private http: HttpClient) {
    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {

      this._total$.next(result.total);
      this._total_payed$.next(result.total_payed / 100);
      this._amount$.next(result.amount / 100);
      this._state.firstItem = result.transactions[0];
      this._state.lastItem = result.transactions[result.transactions.length - 1];

      result.transactions = sort(result.transactions, this.sortColumn, 'desc')

      this._transactions$.next(result.transactions);

      this._cachedCopy = _.cloneDeep(result.transactions);

      if (this._state.prevOrNext != null) {
        if (this._state.prevOrNext == true) {
          this._state.hasNext = result.hasMore;
        }
        else {
          this._state.hasPrev = result.hasMore;
        }
      }
      else {
        this._state.hasNext = result.hasMore;
      }

    });
    this._search$.next();
  }

  getTransactions(): Observable<TransactionsWrapper> {
    let url = `${this.transactionsUrl}`;
    if (this.fromDate != null && this.toDate != null) {
      url = url + "?checkin=" + moment(this.fromDate).unix() + "&checkout=" + moment(this.toDate).unix();
    }
    if (this._state.prevOrNext != null) {
      if (this._state.prevOrNext == false) {
        url = url + "&ending_before=" + this._state.firstItem.id;
      } else {
        url = url + "&starting_after=" + this._state.lastItem.id;
      }
    }
    if (this.payed == true) {
      url = url + "&payed=true";
    }
    console.log(url)
    return this.http.get<TransactionsWrapper>(url).pipe(
      map(item => new TransactionsWrapper().deserialize(item))
    );
  }

  next() {
    this._state.prevOrNext = true;
    this._state.hasPrev = true;
    this._search$.next();
  }

  prev() {
    this._state.prevOrNext = false;
    this._state.hasNext = true;
    this._search$.next();
  }

  get transactions$() { return this._transactions$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get total_payed$() { return this._total_payed$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get searchTerm() { return this._state.searchTerm; }

  get fromDate() {
    return this._state.fromDate;
  }
  get toDate() {
    return this._state.toDate;
  }
  get amount$() { return this._amount$.asObservable(); }
  get payed() { return this._state.payed; }
  get hasPrev() { return this._state.hasPrev; }
  get hasNext() { return this._state.hasNext; }

  set searchTerm(searchTerm: string) {
    this._set2({ searchTerm });
    let transactions = this._transactions$.getValue();
    transactions = this._cachedCopy.filter(transaction => matches(transaction, searchTerm));
    this._transactions$.next(transactions);
  }

  set sortColumn(sortColumn: string) {
    this._set2({ sortColumn });
    let sorted = sort(this._transactions$.getValue(), this._state.sortColumn, this._state.sortDirection)
    this._transactions$.next(sorted);
  }

  set sortDirection(sortDirection: SortDirection) {
    this._set2({ sortDirection });
    let sorted = sort(this._transactions$.getValue(), this._state.sortColumn, this._state.sortDirection)
    this._transactions$.next(sorted);
  }

  set fromDate(fromDate: Date) {
    this._state.prevOrNext = null;
    this._state.hasNext = true;
    this._state.hasPrev = false;
    this._set({ fromDate });
  }

  set payed(payed: boolean) {
    this._set({ payed });
  }

  set toDate(toDate: Date) {
    this._state.prevOrNext = null;
    this._state.hasNext = true;
    this._state.hasPrev = false;
    this._set({ toDate });
  }

  private _set2(patch: Partial<State>) {
    Object.assign(this._state, patch);
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, searchTerm } = this._state;
    return this.getTransactions().pipe(
      switchMap(transactionsWrapper => of({ transactions: transactionsWrapper.data, total: transactionsWrapper.data.length, total_payed: transactionsWrapper.total_payed, amount: transactionsWrapper.total, hasMore: transactionsWrapper.has_more }))
      // map(result => { transactions: sort(result.transactions, sortColumn, sortDirection), total: result.total, amount: result.amount, hasMore: result.hasMore })
    );
  }

}
