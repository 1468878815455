import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Element, Period, SeasonKind } from 'src/app/reservations-table/reservation';
import { ReservationsService } from 'src/app/reservations-table/reservation.service';
import { SpinnerService } from 'src/app/spinner/spinner.service';
import { ReservationWizardFirstComponent } from '../reservation-wizard-first/reservation-wizard-first.component';
import { ReservationWizardThirdComponent } from '../reservation-wizard-third/reservation-wizard-third.component';

@Component({
  selector: 'app-reservation-wizard-second',
  templateUrl: './reservation-wizard-second.component.html',
  styleUrls: ['./reservation-wizard-second.component.scss']
})
export class ReservationWizardSecondComponent implements OnInit {


  @Input() reservation: any;
  period_eunm = Period;
  selected_period: Period;
  name: string;
  surname: string;
  email: string;
  number_people: number;
  checked_in: boolean;
  phone: boolean;

  free_place: boolean;
  selected_place: boolean = false;

  disable_mor: boolean = false;
  disable_aft: boolean = false;
  disable_day: boolean = false;
  @Output() previous_step = new EventEmitter<any>();
  @Output() next_step = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();

  constructor(
    public modalRef: MDBModalRef,
    private reservation_service: ReservationsService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {

    if (this.reservation.make_seasonal) {
      this.free_place = false;
      this.selected_place = true;
    }

    if (this.reservation.name) {
      this.name = this.reservation.name;
      this.surname = this.reservation.surname;
      this.email = this.reservation.email;
      this.number_people = this.reservation.number_people;
      this.selected_period = this.reservation.period;
      this.checked_in = this.reservation.checked_in;
      if (this.reservation.phone)
        this.phone = this.reservation.phone;
    }

    if (this.reservation.checkin != this.reservation.checkout) {
      this.selected_period = Period.DAY;
      this.disable_aft = true;
      this.disable_mor = true;
      return;
    }


    this.reservation_service.getSeasonPricesCheckin(this.reservation.checkin).subscribe(season => {
      this.spinnerService.startLoading();
      let season_kinds = season.kinds;
      this.disable_mor = true;
      this.disable_aft = true;
      this.disable_mor = true;
      console.log(season);
      for (let kind of season_kinds) {
        for (let element of kind.elements) {

          let element_app = new Element();
          Object.assign(element_app, element);
          if (element_app.priceFor(Period.MOR) > 0)
            this.disable_mor = false;
          if (element_app.priceFor(Period.AFT) > 0)
            this.disable_aft = false;
          if (element_app.priceFor(Period.DAY) > 0)
            this.disable_day = false;

        }
      }
      this.spinnerService.stopLoading();
    })
  }


  selectPeriod(period: Period) {
    this.selected_period = period;
  }

  isFormValid() {
    let form_valid: boolean = true;
    if (!this.selected_period)
      form_valid = false;
    if (!this.name || this.name == '')
      form_valid = false;
    if (!this.surname || this.surname == '')
      form_valid = false;
    if (!this.reservation.make_seasonal && (!this.number_people || this.number_people == 0))
      form_valid = false;
    if (!this.selected_place && !this.reservation.place)
      form_valid = false;
    if (this.reservation.make_seasonal && !this.phone)
      form_valid = false;
    return form_valid;
  }


  goToPreviousStep() {
    this.previous_step.emit(this.reservation);
  }

  goToNextStep() {
    this.reservation.name = this.name;
    this.reservation.surname = this.surname;
    this.reservation.email = this.email;
    this.reservation.number_people = this.number_people;
    this.reservation.period = this.selected_period;
    this.reservation.checked_in = this.checked_in;
    this.reservation.free_place = this.free_place;
    if (this.phone)
      this.reservation.phone = this.phone;
    this.next_step.emit(this.reservation);
  }

  closeModal() {
    this.close.emit();
  }

}
