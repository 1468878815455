import { SeasonPeriod } from './../reservations-table/reservation';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Season, SeasonPrice, SeasonElement, Level } from '../reservations-table/reservation';
import { ReservationsService } from '../reservations-table/reservation.service';
import { SpinnerService } from '../spinner/spinner.service';

import * as _ from 'lodash';
import { AuthService } from '../auth.service';
import * as moment from 'moment';
import { format } from 'url';
import { Facility } from '../auth';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DaterangepickerDirective, LocaleConfig } from 'ngx-daterangepicker-material';

@Component({
  selector: 'app-prices-table',
  templateUrl: './prices-table.component.html',
  styleUrls: ['./prices-table.component.scss'],
  providers: [DecimalPipe]
})
export class PricesTableComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    public service: ReservationsService,
    private spinnerService: SpinnerService,
    private authService: AuthService) { }

  seasons: Season[];
  season: Season = null;
  loading: boolean = false;
  fromDate: Date = null;
  toDate: Date = null;
  fromDatePicker: any;
  toDatePicker: any;

  selectedPeriod: SeasonPeriod;
  selectedPriceCopy: SeasonPrice = null;
  selectedPrice: SeasonPrice = null;
  selectedIsWeekend: boolean = false;
  selectedTaxed: number = 0;
  selectedElement: SeasonElement = null;

  facility: Facility = null;

  switch_feriali_festivi: string = "FERIALI";

  locale: LocaleConfig = {
    applyLabel: 'Applica',
    customRangeLabel: ' - ',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek(),
    format: 'DD/MM/YYYY'
  }

  @ViewChildren(DaterangepickerDirective) datePickers: QueryList<DaterangepickerDirective>;


  sortPrices(): void {
    for (let kind of this.season.kinds) {
      for (let element of kind.elements) {
        element.prices = _.sortBy(element.prices, ['price_period']);
        let sortedPrices = [];
        sortedPrices.push(element.prices[1]);
        sortedPrices.push(element.prices[2]);
        sortedPrices.push(element.prices[0]);
        element.prices = sortedPrices;
      }
    }
  }

  async ngOnInit() {
    let facility = await this.authService.getFacilityRemote().toPromise();
    this.authService.resetFacility(facility);
    this.facility = this.authService.getFacility();
    this.seasons = await this.service.getSeasonPrices().toPromise();
    if (this.seasons[0]) {
      this.season = this.seasons[0];
      this.sortPrices();
    }



    /* this.route.paramMap.subscribe(params =>
      this.service.getSeason(+params.get('season_id')).subscribe(season_ => {
        this.season = season_;
        this.sortPrices();
      })
    ) */
  }

  selectPriceToEdit(price: SeasonPrice, element: SeasonElement, isWeekend: boolean) {
    this.selectedPrice = price;
    this.selectedIsWeekend = isWeekend;
    this.selectedPriceCopy = _.cloneDeep(this.selectedPrice);
    this.selectedElement = element;
    this.calculatePrice();
  }

  selectPeriodsToEdit(periods: SeasonPeriod) {
    this.selectedPeriod = periods;
    this.fromDatePicker = {
      startDate: moment(this.selectedPeriod.date_start),
      endDate: moment(this.selectedPeriod.date_start),
    }
    this.toDatePicker = {
      startDate: moment(this.selectedPeriod.date_end),
      endDate: moment(this.selectedPeriod.date_end),
    }
  }

  async postSeasonDate() {
    try {
      if (this.fromDatePicker && this.toDatePicker) {
        const f_date = moment(this.fromDatePicker.startDate).format("YYYY-MM-DD");
        const t_date = moment(this.toDatePicker.endDate).format("YYYY-MM-DD");

        await this.service.postSeasonDateRemote(f_date, t_date, this.selectedPeriod.id).toPromise();
        this.seasons = await this.service.getSeasonPrices().toPromise();
        if (this.seasons[0]) {
          this.season = this.seasons[0];
          this.sortPrices();
        }
      }
    } catch (error) {
      console.log('Bad post', error)
    }
  }

  selectElementToEdit(element: SeasonElement) {
    this.selectedElement = element
  }

  getTax(): number {
    return this.authService.getFacility().fee;
  }

  calculatePrice() {
    if (this.selectedIsWeekend == false) {
      this.selectedTaxed = this.selectedPriceCopy.price + this.selectedPriceCopy.price * this.getTax() / 100.0;
    } else {
      this.selectedTaxed = this.selectedPriceCopy.weekend + this.selectedPriceCopy.weekend * this.getTax() / 100.0;
    }
  }

  async saveForm() {
    this.selectedPrice.price = this.selectedPriceCopy.price;
    this.selectedPrice.weekend = this.selectedPriceCopy.weekend;
    await this.save();
  }

  // hasShadowElements() : boolean {
  //   for(let element of this.season.elements) {
  //     if (element.is_shadow) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  startLoading(): void {
    this.spinnerService.startLoading();
  }

  stopLoading(): void {
    this.spinnerService.stopLoading();
  }

  isLoading(): boolean {
    return this.spinnerService.isLoading();
  }

  getLevelImage(level: Level): string {
    if (level === Level.NRM) {
      return "assets/img/stella_basic.svg";
    }
    else if (level === Level.SLV) {
      return "assets/img/gold.png";
    }
    else if (level === Level.GLD) {
      return "assets/img/platinum.png";
    }
  }

  async save() {
    this.startLoading();
    this.postSeasonDate();
    this.season = await this.service.setSeason(this.season).toPromise();
    this.sortPrices();
    this.stopLoading();
  }


  openFromDatePicker() {
    this.datePickers.first.toggle();
  }

  openToDatePicker() {
    this.datePickers.last.toggle();
  }

}
