import { ElementIcon } from "./reservations-table/reservation";

interface Deserializable {
  deserialize(input: any): this;
}

export class Login {
  username: string;
  password: string;
  grant_type: string = 'password';
  client_id: string = 'id.client.bxe';
}

export class RefreshToken {
  refresh_token: string;
  grant_type: string = 'refresh_token';
  client_id: string = 'id.client.bxe';
}

export class LoginResult implements Deserializable {
  'expires_in': number;
  'refresh_token': string;
  'scope': string;
  'access_token': string;
  'token_type': string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}


export class RemoteImage implements Deserializable {
  id: number;
  image: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class PresenceInfo implements Deserializable {
  presence: number;
  effective_presence: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Tag implements Deserializable {
  keyword: string;
  name: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Category implements Deserializable {
  beach: Tag[];
  food_service: Tag[];
  service: Tag[];

  deserialize(input: any): this {
    Object.assign(this, input);
    if (this.beach) {
      this.beach = this.beach.map(item => new Tag().deserialize(item));
    }
    if (this.food_service) {
      this.food_service = this.food_service.map(item => new Tag().deserialize(item));
    }
    if (this.service) {
      this.service = this.service.map(item => new Tag().deserialize(item));
    }
    return this;
  }
}

export class Coordinates implements Deserializable {
  type: string;
  coordinates: Array<number>;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class UserInfo implements Deserializable {
  id: number;
  last_login: Date;
  is_superuser: boolean;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  role: string;
  state: string;
  is_staff: boolean;
  date_joined: Date;
  facility: number;
  password?: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class TotalizerElement implements Deserializable {
  id: number;
  name: string;
  selled: number;
  quantity: number;
  available: number;
  reserved: number;
  icon: ElementIcon;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class Facility implements Deserializable {
  fee: number;
  address: string;
  name: string;
  description: string;
  ragione_sociale: string;
  pec: string;
  email: string;
  enabled: boolean;
  id: number;
  images: RemoteImage[];
  logo: RemoteImage;
  phone: string;
  vat: string;
  superficie: Number;
  max_presenti: Number;
  max_adulti: Number;
  max_bambini: Number;
  categories: Category;
  coord: Coordinates;
  lat: Number;
  lon: Number;
  whitelist_enabled: false;
  only_freeplace: false;
  stripe_enabled: false;
  stripe_acc_id: string;
  timetables: { time_end: string; time_start: string; date_range: [string, string] }[];
  close_periods: { id: number; date_start: Date; date_end: Date }[];

  deserialize(input: any) {
    Object.assign(this, input);
    if (this.logo) {
      this.logo = new RemoteImage().deserialize(this.logo);
    }
    if (this.images) {
      this.images = this.images.map(item => new RemoteImage().deserialize(item));
    }
    if (this.coord) {
      this.coord = new Coordinates().deserialize(this.coord);
      this.lat = this.coord.coordinates[0]
      this.lon = this.coord.coordinates[1]
    }
    if (this.categories) {
      this.categories = new Category().deserialize(this.categories);
    }
    return this;
  }
}
