import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { NewReservationElement, Period, ReservationElement, SeasonElement } from 'src/app/reservations-table/reservation';
import { ReservationsService } from 'src/app/reservations-table/reservation.service';
import { SpinnerService } from 'src/app/spinner/spinner.service';

@Component({
  selector: 'app-reservation-wizard-third',
  templateUrl: './reservation-wizard-third.component.html',
  styleUrls: ['./reservation-wizard-third.component.scss']
})
export class ReservationWizardThirdComponent implements OnInit {

  period: Period = Period.DAY;
  season_elements: SeasonElement[];
  elements_rows: any[] = [];
  @Input() reservation: any;
  show_price_daily: boolean = false;
  total_elements: number;
  total_price: number;
  total_price_string: string;
  range_days: number;

  @Output() previous_step = new EventEmitter<any>();
  @Output() next_step = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();



  constructor(
    private service: ReservationsService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {

    let checkin = moment(this.reservation.checkin, 'YYYY-MM-DD');
    let checkout = moment(this.reservation.checkout, 'YYYY-MM-DD');
    this.range_days = checkout.diff(checkin, 'days');
    if (this.range_days > 29)
      this.show_price_daily = true;
    this.spinnerService.startLoading();
    this.service.getElements(this.reservation.checkin, this.reservation.checkout, this.period).subscribe(season_elements => {
      this.season_elements = season_elements;
      this.season_elements.forEach((element, index) => {
        element.elements.forEach(el => {
          this.elements_rows.push({
            element_id: el.id,
            element_name: el.element_name,
            element_price: el.price,
            element_price_daily: el.price_daily,
            element_quantity: el.suggested > 0 && index != 0 ? el.suggested : 0,
            element_icon: el.icon.image,
            element_is_shadow: index == 0, // la prima categoria è sempre shadow
            element_is_checked: false,
            element_maximum: el.maximum
          })
        })
      })
      if (this.reservation.elements)
        this.fillElementsQuantity();
      this.calculateTotalElements();
      this.spinnerService.stopLoading();
    });
  }

  fillElementsQuantity() {
    this.reservation.elements.forEach(element => {
      this.elements_rows.find(row => row.element_id == element.id).element_quantity = element.quantity;
      if (element.quantity > 0)
        this.elements_rows.find(row => row.element_id == element.id).element_is_checked = true;
    })

  }


  oncheckShadowChange(event: any, element_id: number) {
    this.elements_rows.forEach(element => {
      if (element.element_is_shadow) {
        element.element_quantity = 0;
        element.element_is_checked = false;
      }

    })
    this.elements_rows.find(element => element.element_id == element_id).element_quantity = 1;
    this.elements_rows.find(element => element.element_id == element_id).element_is_checked = true;
    this.calculateTotalElements();
  }

  calculateTotalElements() {
    this.total_elements = 0;
    this.total_price = 0;
    this.elements_rows.forEach(element => {
      this.total_elements += element.element_quantity;
      let quantity: number = element.element_quantity;
      let price: number = element.element_price * 1;
      this.total_price += (Math.round(price * quantity * 100) / 100);
    })
    this.total_price_string = this.total_price.toFixed(2);
  }

  goToPreviousStep() {
    this.previous_step.emit(this.reservation);
  }

  goToNextStep() {
    let reservation_elements: NewReservationElement[] = [];
    this.elements_rows.forEach(element => {
      if (element.element_quantity > 0)
        reservation_elements.push(
          {
            id: element.element_id,
            quantity: element.element_quantity
          }
        )
    })
    this.reservation.elements = reservation_elements;
    this.next_step.emit(this.reservation);
  }

  closeModal() {
    this.close.emit();
  }

  disableNextButton() {
    return this.elements_rows.find(row => row.element_quantity > 0) ? false : true;
  }

}
