import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Facility } from '../auth';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WalletModalComponent } from './../wallet-modal/wallet-modal.component';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {

  constructor(public service: AuthService, public matDialog: MatDialog) { }


  stripe_url = 'https://dashboard.stripe.com/';
  facility: Facility = null;

  ngOnInit() {
    this.syncData()
  }

  async syncData() {
    let facility = await this.service.getFacilityRemote().toPromise();
    this.service.resetFacility(facility);
    this.facility = this.service.getFacility();
  }

  openModal() {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = false;
    dialogConfig.id = "wallet-modal";
    dialogConfig.height = "160px";
    dialogConfig.width = "540px";
    // https://material.angular.io/components/dialog/overview
    const modalDialog = this.matDialog.open(WalletModalComponent, dialogConfig);
  }

  async goToWallet(kind: string) {
    console.log("Gathering Info");

    let wallet_link = await this.service.getMyWalletStripeRemote().toPromise();

    if (wallet_link) {
      console.log(wallet_link)
      window.open(wallet_link).focus()
    } else {
      window.open(this.stripe_url).focus()
    }

  }

  goToStripeURL() {
    window.open(this.stripe_url).focus()
  }

}
