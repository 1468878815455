import { Component, ViewChild, ElementRef, AfterViewInit, Input, AfterContentInit } from '@angular/core';
import { ReservationsService } from '../reservations-table/reservation.service';
import { MapPlaces, MapPlace, Area, Level, Reservation, NewReservation } from '../reservations-table/reservation';
import { DecimalPipe } from '@angular/common';
import { Period } from '../reservations-table/reservation';
import * as _ from 'lodash';
import * as moment from 'moment';
import { FormNewReservationComponent } from '../form-new-reservation/form-new-reservation.component';
import { SpinnerService } from '../spinner/spinner.service';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ReservationWizardFirstComponent } from '../modals/reservation-wizard-first/reservation-wizard-first.component';
import { ReservationWizardCompleteComponent } from '../modals/reservation-wizard-complete/reservation-wizard-complete.component';
import { AuthService } from '../auth.service';

const prefix = 'assets/img/';
@Component({
  selector: 'app-area-map',
  templateUrl: './area-map.component.html',
  styleUrls: ['./area-map.component.scss'],
  providers: [DecimalPipe]
})
export class AreaMapComponent implements AfterContentInit {

  // @ViewChild('mapDetailButton') mapDetailButton: ElementRef;
  @ViewChild('mapCheckinButton', { static: true }) mapCheckinButton: ElementRef;
  @ViewChild('mapReserveButton', { static: true }) mapReserveButton: ElementRef;
  @ViewChild(FormNewReservationComponent, { static: false }) formNewReservation: FormNewReservationComponent;

  mapDetailVisible: boolean = false;

  loading_checkin: boolean = false;

  _loading: boolean = false;
  _places: MapPlaces = null;
  _areas: Area[] = null;
  _date: Date = null;
  _presence: Number = null;
  _effective_presence: Number = null;
  _period: Period = Period.MOR;

  _selectedPlace: MapPlace = null;
  _selectedMorning: Reservation = null;
  _selectedAfternoon: Reservation = null;
  _selectedDay: Reservation = null;
  _checkinReservation: Reservation = null;
  _periodToReserve: Period = null;

  selectedAreaId: number;


  modalRef: MDBModalRef;

  refresh: boolean;

  constructor(
    public service: ReservationsService,
    private spinnerService: SpinnerService,
    private modalService: MDBModalService,
    private authService: AuthService
  ) { }

  async ngAfterContentInit() {
    this.area = null;
    await this.getAreas();
    this.modalService.closed.subscribe(() => {
      if (this.modalService.config.data['finished']) {
        this.showCheckinSpinner();
        this.getPlaces();
        this.refreshMap();
        this.hideCheckinSpinner();
      }
    });
  }

  onChangeEvent(newValue: number) {
    this.area = newValue;
    // ... do other stuff here ...
  }

  onChangePeriod(period: Period) {
    this._periodToReserve = period;
    // this.period = Period[period];
  }

  public set period(period: Period) {
    this._period = period;
    this.getPlaces();
  }

  @Input()
  public set date(date: Date) {
    this._date = date;
    this.getPlaces();
  }

  public get date() {
    return this._date;
  }

  set area(area: number) {
    this.service.setCurrentMapArea(area);
    this.getPlaces();
  }

  get area() {
    return this.service.getCurrentMapArea();
  }

  counter(i: number) {
    return new Array(i);
  }

  showSpinner() {
    this._loading = true;
  }

  hideSpinner() {
    this._loading = false;
  }

  async getPlaces() {
    if (this.area == null || this._date == null) { return; }

    this.showSpinner();

    const years = moment(this.date).year();
    const months = moment(this.date).month() + 1;
    const days = moment(this.date).date();

    const date_: string = years + '-' + months + '-' + days;

    // this._places = await this.service.getPlacesWithDateAndPeriod(this.area, date_, this._period).toPromise();
    this._places = await this.service.getPlacesWithDate(this.area, date_).toPromise();
    this._presence = this._places.presence
    this._effective_presence = this._places.effective_presence
    this._places.places = _.sortBy(this._places.places, ['row', 'col']);
    this.hideSpinner();
  }

  getPlace(row: number, col: number): MapPlace {
    return this._places.places[row * this._places.col + col];
  }

  async getAreas() {
    this.showSpinner();
    this._areas = await this.service.getAreas().toPromise();
    if (this.area == null && this._areas.length > 0) {
      this.area = this._areas[0].id;
      this.selectedAreaId = 0;
    }
    this.hideSpinner();
  }

  getMorningImage(place: MapPlace): string {
    if (place.reservation == null) {
      return 'assets/img/mattina_vendibile.png';
    }
    for (const reservation of place.reservation) {
      if (reservation.period == Period.MOR) {
        if (reservation.offline == false && !reservation.checked_in) {
          return 'assets/img/mattina_online.svg';
        } else if (reservation.offline == false && reservation.checked_in) {
          return 'assets/img/mattina_checkin_online.svg';
        } else if (reservation.offline == true && !reservation.checked_in) {
          return 'assets/img/mattina_offline.svg';
        } else if (reservation.offline == true && reservation.checked_in) {
          return 'assets/img/mattina_checkin_offline.svg';
        }
      } else if (reservation.period == Period.DAY) {
        if (reservation.offline == false && !reservation.checked_in) {
          return 'assets/img/mattina_online-pieno.svg';
        } else if (reservation.offline == false && reservation.checked_in) {
          return 'assets/img/mattina_checkin_online-pieno.png';
        } else if (reservation.offline == true && !reservation.checked_in) {
          return 'assets/img/mattina_offline-pieno.svg';
        } else if (reservation.offline == true && reservation.checked_in) {
          return 'assets/img/mattina_checkin_offline-pieno.png';
        }
      }
    }
    return 'assets/img/mattina_vendibile.png';
  }

  getAfternoonImage(place: MapPlace): string {
    if (place.reservation == null) {
      return 'assets/img/sera_vendibile.png';
    }
    for (const reservation of place.reservation) {
      if (reservation.period == Period.AFT) {
        if (reservation.offline == false && !reservation.checked_in) {
          return 'assets/img/sera_online.svg';
        } else if (reservation.offline == false && reservation.checked_in) {
          return 'assets/img/sera_checkin_online.svg';
        } else if (reservation.offline == true && !reservation.checked_in) {
          return 'assets/img/sera_offline.svg';
        } else if (reservation.offline == true && reservation.checked_in) {
          return 'assets/img/sera_checkin_offline.svg';
        }
      } else if (reservation.period == Period.DAY) {
        if (reservation.offline == false && !reservation.checked_in) {
          return 'assets/img/sera_online-pieno.svg';
        } else if (reservation.offline == false && reservation.checked_in) {
          return 'assets/img/sera_checkin_online-pieno.png';
        } else if (reservation.offline == true && !reservation.checked_in) {
          return 'assets/img/sera_offline-pieno.svg';
        } else if (reservation.offline == true && reservation.checked_in) {
          return 'assets/img/sera_checkin_offline-pieno.png';
        }
      }
    }
    return 'assets/img/sera_vendibile.png';
  }

  getShadowElement(place: MapPlace): string {
    if (place.shadow_element == null) {
      return 'assets/img/mappa_disabilitato.svg';
    } else {
      return 'assets/img/map_' + place.shadow_element.keyword + '.svg';
    }
  }

  getMorningGazebo(place: MapPlace): string {
    if (place.reservation == null) {
      return prefix + 'gazebo_vendibile_pieno_mattina.png';
    }
    for (const reservation of place.reservation) {
      if (reservation.period == Period.MOR) {
        return prefix + 'gazebo_prenotato_mattina.png';
      } else if (reservation.period == Period.DAY) {
        return prefix + 'gazebo_prenotato_pieno_mattina.png';
      }
    }
    return prefix + 'gazebo_vendibile_mattina.png';
  }

  getAfternoonGazebo(place: MapPlace): string {
    if (place.reservation == null) {
      return prefix + 'gazebo_vendibile_pieno_sera.png';
    }
    for (const reservation of place.reservation) {
      if (reservation.period == Period.AFT) {
        return prefix + 'gazebo_prenotato_sera.png';
      } else if (reservation.period == Period.DAY) {
        return prefix + 'gazebo_prenotato_pieno_sera.png';
      }
    }
    return prefix + 'gazebo_vendibile_sera.png';
  }

  getMorningOmbrellone(place: MapPlace): string {
    if (place.reservation == null) {
      return prefix + 'ombrellone_vendibile_pieno_mattina.png';
    }
    for (const reservation of place.reservation) {
      if (reservation.period == Period.MOR) {
        return prefix + 'ombrellone_prenotato_mattina.png';
      } else if (reservation.period == Period.DAY) {
        return prefix + 'ombrellone_prenotato_pieno_mattina.png';
      }
    }
    return prefix + 'ombrellone_vendibile_mattina.png';
  }

  getAfternoonOmbrellone(place: MapPlace): string {
    if (place.reservation == null) {
      return prefix + 'ombrellone_vendibile_pieno_sera.png';
    }
    for (const reservation of place.reservation) {
      if (reservation.period == Period.AFT) {
        return prefix + 'ombrellone_prenotato_sera.png';
      } else if (reservation.period == Period.DAY) {
        return prefix + 'ombrellone_prenotato_pieno_sera.png';
      }
    }
    return prefix + 'ombrellone_vendibile_sera.png';
  }

  getMorningShadowElement(place: MapPlace): string {
    if (place.shadow_element == null) {
      return 'assets/img/mappa_disabilitato.svg';
    }
    if (place.shadow_element.keyword == 'gazebo') {
      return this.getMorningGazebo(place);
    } else if (place.shadow_element.keyword == 'umbrella') {
      return this.getMorningOmbrellone(place);
    }
  }

  getAfternoonShadowElement(place: MapPlace): string {
    if (place.shadow_element == null) {
      return 'assets/img/mappa_disabilitato.svg';
    }
    if (place.shadow_element.keyword == 'gazebo') {
      return this.getAfternoonGazebo(place);
    } else if (place.shadow_element.keyword == 'umbrella') {
      return this.getAfternoonOmbrellone(place);
    }
  }

  isSharedPlace(place: MapPlace): boolean {
    if (place.reservation == null) { return false; }
    for (const reservation of place.reservation) {
      if (reservation.shared_on != null) { return true; }
    }
  }

  getPlaceLevelImage(place: MapPlace): string {
    if (place.level === Level.NRM) {
      return 'assets/img/stella_basic.png';
    } else if (place.level === Level.SLV) {
      return 'assets/img/stella_gold.svg';
    } else if (place.level === Level.GLD) {
      return 'assets/img/stella_platinum.svg';
    }
  }

  getPlaceLevelImageLarge(place: MapPlace): string {
    if (place.level === Level.NRM) {
      return 'assets/img/stella_basic.svg';
    } else if (place.level === Level.SLV) {
      return 'assets/img/gold.png';
    } else if (place.level === Level.GLD) {
      return 'assets/img/platinum.png';
    }
  }

  getPlaceLevelText(place: MapPlace): string {
    if (place.level === Level.NRM) {
      return 'Basic';
    } else if (place.level === Level.SLV) {
      return 'Silver';
    } else if (place.level === Level.GLD) {
      return 'Gold';
    }
  }

  getElementImage(place: MapPlace): string {
    if (place.shadow_element != null) {
      return 'assets/img/' + place.shadow_element.keyword + '.png';
    } else {
      return 'assets/img/libero.svg';
    }
  }

  getElementText(place: MapPlace): string {
    if (place.shadow_element != null) {
      return place.shadow_element.name;
    } else {
      return 'Libero';
    }
  }

  // Place Detail

  public openCheckinModal(reservation: Reservation) {
    this._checkinReservation = reservation;
    // this.mapDetailButton.nativeElement.click();
    // this.mapDetailVisible = !this.mapDetailVisible;
    this.mapCheckinButton.nativeElement.click();
  }

  public closeCheckinModal() {
    this.mapCheckinButton.nativeElement.click();
    // this.mapDetailButton.nativeElement.click();
    // this.mapDetailVisible = !this.mapDetailVisible;
  }

  public openReservationModal(period: Period) {
    this._periodToReserve = period;
    // this.mapDetailButton.nativeElement.click();
    // this.mapDetailVisible = !this.mapDetailVisible;
    this.mapReserveButton.nativeElement.click();
  }

  public closeReservationModal() {
    this.mapReserveButton.nativeElement.click();
    // this.mapDetailButton.nativeElement.click();
    // this.mapDetailVisible = !this.mapDetailVisible;
  }

  public async doReservation() {
    // let newReservation = new NewReservation();
    // newReservation.checkin = this._date;
    // newReservation.place = this._selectedPlace.id;
    // newReservation.checkout = this._date;
    // newReservation.period = this._periodToReserve;
    // newReservation.offline = true;

    this.closeReservationModal();
    this.showCheckinSpinner();
    this._loading = true;

    const reservation = await this.formNewReservation.makeReservation();

    // let reservation = await this.service.makeReservation(newReservation).toPromise();
    if (reservation.period == Period.DAY) {
      this._selectedDay = reservation;
    } else if (reservation.period == Period.MOR) {
      this._selectedMorning = reservation;
    } else if (reservation.period == Period.AFT) {
      this._selectedAfternoon = reservation;
    }
    await this.getAreas()
    await this.refreshMap();
    // let years = moment(this.date).year();
    // let months = moment(this.date).month() + 1;
    // let days = moment(this.date).date();

    // let date_: string = years + "-" + months + "-" + days;
    // this._places = await this.service.getPlacesWithDate(this.area, date_).toPromise();
    // this._places.places = _.sortBy(this._places.places, ['row', 'col']);
    this.hideCheckinSpinner();
    this._loading = false;
  }

  async refreshMap() {
    const years = moment(this.date).year();
    const months = moment(this.date).month() + 1;
    const days = moment(this.date).date();
    const date_: string = years + '-' + months + '-' + days;
    this._places = await this.service.getPlacesWithDate(this.area, date_).toPromise();
    this._places.places = _.sortBy(this._places.places, ['row', 'col']);
    this.refresh = true;
    return true;
  }

  public getReservationPeriodName() {
    if (this._periodToReserve == Period.AFT) {
      return 'Pomeriggio';
    } else if (this._periodToReserve == Period.MOR) {
      return 'Mattina';
    } else if (this._periodToReserve == Period.DAY) {
      return 'Tutto il giorno';
    }
  }

  public openPlaceDetail(place: MapPlace) {

    this._selectedDay = null;
    this._selectedMorning = null;
    this._selectedAfternoon = null;


    if (this._selectedPlace && place.id == this._selectedPlace.id) {
      this._selectedPlace = null;
      this.mapDetailVisible = false;
      return;
    }

    this._selectedPlace = null;

    this._selectedPlace = place;
    if (place.reservation != null) {
      for (const reservation of place.reservation) {
        if (reservation.period == Period.AFT) {
          this._selectedAfternoon = reservation;
        } else if (reservation.period == Period.MOR) {
          this._selectedMorning = reservation;
        } else if (reservation.period == Period.DAY) {
          this._selectedDay = reservation;
        }
      }
    }
    // this.mapDetailButton.nativeElement.click();
    this.mapDetailVisible = true;
  }

  public closePlaceDetail() {
    // this.mapDetailButton.nativeElement.click();
    this.mapDetailVisible = false;
  }

  showCheckinSpinner() {
    // this.loading_checkin = true;
    this.spinnerService.startLoading();
  }

  hideCheckinSpinner() {
    // this.loading_checkin = false;
    this.spinnerService.stopLoading();
  }

  async doCheckin() {
    this.showCheckinSpinner();
    this.mapCheckinButton.nativeElement.click();
    // this.mapDetailButton.nativeElement.click();
    // this.mapDetailVisible = !this.mapDetailVisible;
    const updatedReservation = await this.service.getReservation(this._checkinReservation.id).toPromise();
    updatedReservation.checked_in = true;
    this._checkinReservation = await this.service.updateReservation(updatedReservation).toPromise();
    if (this._checkinReservation.period == Period.DAY) {
      this._selectedDay = this._checkinReservation;
    } else if (this._checkinReservation.period == Period.MOR) {
      this._selectedMorning = this._checkinReservation;
    } else if (this._checkinReservation.period == Period.AFT) {
      this._selectedAfternoon = this._checkinReservation;
    }
    await this.refreshMap();
    this.hideCheckinSpinner();
  }

  async makeShareble() {
    this.showCheckinSpinner();
    this._selectedPlace.eligible = !this._selectedPlace.eligible;
    await this.service.modifyPlace(this._selectedPlace).toPromise();
    this._loading = true;
    await this.refreshMap();
    this._loading = false;
    this.hideCheckinSpinner();
  }

  async makeMarketable() {
    this.showCheckinSpinner();
    this._selectedPlace.marketable = !this._selectedPlace.marketable;
    await this.service.modifyPlace(this._selectedPlace).toPromise();
    this._loading = true;
    await this.refreshMap();
    this._loading = false;
    this.hideCheckinSpinner();
  }

  openReservationWizardModal(selectedPlace: MapPlace) {
    let new_reservation: any = {
      place: selectedPlace.id,
      place_object: selectedPlace,
      area_object: this._areas.find(area => area.id == this.area),
      facility: this.authService.getUserInfo().facility,
      checkin: moment(this._date).format('YYYY-MM-DD'),
      checkout: moment(this._date).format('YYYY-MM-DD'),
      skip_date_selection: true,
    }
    this.modalRef = this.modalService.show(ReservationWizardCompleteComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      data: {
        reservation: new_reservation,
        step_selected: 2
      }
    });
  }

  getAreaNameFromId(id: number) {
    return this._areas.find(area => area.id == id).name;
  }

  changeArea() {
    if (this.selectedAreaId >= this._areas.length - 1)
      this.selectedAreaId = 0;
    else
      this.selectedAreaId++;
    this.area = this._areas[this.selectedAreaId].id;
  }



}
