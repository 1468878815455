import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { Facility, UserInfo } from '../auth';

@Component({
  selector: 'app-wallet-modal',
  templateUrl: './wallet-modal.component.html',
  styleUrls: ['./wallet-modal.component.scss']
})
export class WalletModalComponent implements OnInit {

  constructor(public service: AuthService,public dialogRef: MatDialogRef<WalletModalComponent>) { }

  redirect_stripe_url = 'https://app.beachxperience.com/api/redirect/';
  create_stripe_url = 'https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_FHGHqql2GRsfEulKQsQx2cwCZfSDhhLA&suggested_capabilities[]=transfers&suggested_capabilities[]=card_payments&stripe_landing=register';
  connect_stripe_url = 'https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_FHGHqql2GRsfEulKQsQx2cwCZfSDhhLA&suggested_capabilities[]=transfers&suggested_capabilities[]=card_payments&stripe_landing=login';
  
  facility: Facility = null;
  user: UserInfo = null;

  async ngOnInit() {
    this.user = this.service.getUserInfo();
    this.facility = await this.service.getFacilityRemote().toPromise();
  }

  createWallet(kind: string) {
    console.log("Gathering Info");

    console.log("Creating URL");
    switch(kind) { 
      case 'stripe': { 
         this.createStripeURL()
         break; 
      } 
      default: {   
         // Not implemente yet; 
         break; 
      } 
   } 
    
  }

  createStripeURL() {
    let state = `${this.user.id}`
    let url = `${this.create_stripe_url}&state=${state}&stripe_user[email]=${this.user.email}&stripe_user[phone]=${this.facility.phone}
    &stripe_user[first_name]=${this.user.first_name}&stripe_user[last_name]=${this.user.first_name}
    &stripe_user[business_name]=${this.facility.name}&stripe_user[business_type]=corporation`
    window.open(url).focus()
  }

  connectWallet(kind: string) {
    console.log("Gathering Info");

    console.log("Creating URL");
    switch(kind) { 
      case 'stripe': { 
         this.connectStripeURL()
         break; 
      } 
      default: { 
         // Not implemente yet; 
         break; 
      } 
   } 
    
  }

  connectStripeURL() {
    let state = `${this.user.id}`
    let url = `${this.connect_stripe_url}&state=${state}&stripe_user[email]=${this.user.email}&stripe_user[phone]=${this.facility.phone}
    &stripe_user[first_name]=${this.user.first_name}&stripe_user[last_name]=${this.user.first_name}
    &stripe_user[business_name]=${this.facility.name}&stripe_user[business_type]=corporation`
    window.open(url).focus()
  }

}
