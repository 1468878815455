import { PipeTransform, Pipe } from '@angular/core';
import { ReservationItem, ReservationItemWithType, ReservationItemExt } from './reservations-table/reservation';
@Pipe({ name: 'dictToArray' })
export class DictToArrayPipe implements PipeTransform {
  transform(dict: { [type: string]: ReservationItem[] }, args: string[]): ReservationItemWithType[] {
    let array = [];
    for (let key in dict) {
      let items: ReservationItem[] = dict[key];
      items.forEach(item => array.push({ type: key, item: item }));
    }
    return array;
  }
}

// @Pipe({ name: 'reservationExpand' })
// export class ReservationExpandPipe implements PipeTransform {
//   transform(items: ReservationItem[], args: string[]): ReservationItemExt[] {
//     let array: ReservationItemExt[];
//     for (let item of items) {
//       for (let el of item.elements) {
//         var itemExt = new ReservationItemExt;
//         itemExt.id = item.id;
//         itemExt.place = item.place
//         itemExt.price = el.price;
//         itemExt.total = el.total;
//         itemExt.quantity = el.quantity;
//         itemExt.element = el.element;
//         array.push(itemExt);
//       }
//     }
//     return array;
//   }
// }
