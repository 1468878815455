import { Facility } from './../auth';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

export interface Deserializable {
  deserialize(input: any): this;
}

export interface ReservationItemWithType {
  type: string;
  item: ReservationItem;
}

export interface SharedDates {
  checkin: NgbDateStruct;
  checkout: NgbDateStruct;
}


export class SeasonKind implements Deserializable {

  id: number;
  name: string;
  elements: SeasonElement[];

  deserialize(input: any) {
    Object.assign(this, input);
    if (this.elements) {
      this.elements = this.elements.map(item => new SeasonElement().deserialize(item));
    }
    return this;
  }

}

export class Season implements Deserializable {
  id: number;
  season_type: string;
  periods: SeasonPeriod[];
  kinds: SeasonKind[];

  deserialize(input: any) {
    Object.assign(this, input);
    if (this.periods) {
      this.periods = this.periods.map(item => new SeasonPeriod().deserialize(item));
    }
    if (this.kinds) {
      this.kinds = this.kinds.map(item => new SeasonKind().deserialize(item));
    }
    return this;
  }
}

export class SeasonPeriod implements Deserializable {
  id: number;
  date_start: Date;
  date_end: Date;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class SeasonPrice implements Deserializable {
  id: number;
  price: number;
  weekend: number;
  price_taxed: number;
  weekend_taxed: number;
  price_period: Period;
  period_text: string;
  is_active: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class SeasonElement implements Deserializable {
  id: number;
  prices: SeasonPrice[];
  elements: Element[];
  name: string;

  deserialize(input: any) {
    Object.assign(this, input);
    if (this.prices) {
      this.prices = this.prices.map(item => new SeasonPrice().deserialize(item))
    }
    return this;
  }

  priceFor(period: Period): number {
    this.prices.forEach(price => {
      if (price.price_period == period) {
        if (price.is_active!)
          return price.price_taxed;
        else
          return 0;
      }
    })
    return 0;
  }
}

export class Element implements Deserializable {
  id: number;
  icon: ElementIcon;
  keyword: string;
  name: string;
  is_shadow: boolean;
  element_name: string;
  price: number;
  price_daily: number;
  quantity: number;
  kind: number;
  minimum: number;
  maximum: number;
  suggested: number;
  prices: SeasonPrice[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  priceFor(period: Period): number {
    let price_obj = this.prices.find(price => price.price_period == period);
    if (price_obj && price_obj.is_active)
      return price_obj.price_taxed;
    else
      return 0;
  }
}

export interface ElementIcon {
  id: number;
  image: string;
}

export class MapPlace implements Deserializable {
  area: Area;
  id: number;
  row: number;
  col: number;
  eligible: boolean;
  marketable: boolean;
  free: boolean;
  level: string;
  shadow_element: Element;
  reservation: Reservation[];
  name: string;

  deserialize(input: any) {
    Object.assign(this, input);
    if (this.shadow_element) {
      this.shadow_element = new Element().deserialize(this.shadow_element);
    }
    if (this.reservation) {
      if (!_.isArray(this.reservation)) {
        this.reservation = [this.reservation];
      }
      this.reservation = this.reservation.map(item => new Reservation().deserialize(item))
    }
    if (this.area) {
      this.area = new Area().deserialize(this.area);
    }
    return this;
  }


}

export class Place implements Deserializable {
  area: Area;
  id: number;
  row: number;
  col: number;
  eligible: boolean;
  marketable: boolean;
  free: boolean;
  shadow_element: Element;
  level: string;
  name: string;

  deserialize(input: any) {
    Object.assign(this, input);
    if (this.shadow_element) {
      this.shadow_element = new Element().deserialize(this.shadow_element);
    }
    if (this.area) {
      this.area = new Area().deserialize(this.area);
    }
    return this;
  }
}

export class MapPlaces {
  row: number;
  col: number;
  presence: number;
  effective_presence: number;
  places: MapPlace[];

  deserialize(input: any) {
    Object.assign(this, input);
    if (this.places) {
      this.places = this.places.map(item => new MapPlace().deserialize(item))
    }
    return this;
  }
}

export class MapRequest {
  area: number;
}

export class NewReservationElement {
  id: number;
  quantity: number;
}

export class AssignPlaceRequest {
  place: number;
  item: number;
}

export class NewReservation {
  place: number;
  facility: number;
  checkin: string;
  checkout: string;
  period: Period;
  offline: boolean = true;
  checked_in: boolean;
  presence: number;
  elements: NewReservationElement[];
  // payment_period_id: string;
  promo_code: string;
  confirm: boolean;
  name: string;
  surname: string;
  email: string;
  number_people: number;
  seasonal: boolean;


  getReservationPeriod(): string {
    if (this.period === "MOR") {
      return "Mattina";
    } else if (this.period === "AFT") {
      return "Pomeriggio";
    } else if (this.period === "DAY") {
      return "Tutto il giorno";
    }
  }
}

export enum ErrorMessages {
  invalid_promo_code = "Codice promo non valido"
}

export enum Period {
  MOR = "MOR",
  AFT = "AFT",
  DAY = "DAY"
}

export enum Level {
  NRM = "NRM",
  SLV = "SLV",
  GLD = "GLD"
}

export class Area implements Deserializable {
  id: number;
  name: string;
  row: number;
  col: number;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class ReservationElement implements Deserializable {

  deserialize(input: any) {
    Object.assign(this, input);
    if (this.element_type) {
      this.element_type = new Element().deserialize(this.element_type);
    }
    return this;
  }

  price: number;
  price_total: number;
  quantity: number;
  element_type: Element;
}

export class ReservationItem implements Deserializable {

  deserialize(input: any) {
    Object.assign(this, input);
    if (this.reservation_elements) {
      this.reservation_elements = this.reservation_elements.map(item => new ReservationElement().deserialize(item))
    }
    if (this.place) {
      this.place = new Place().deserialize(this.place);
    }
    return this;
  }

  id: number;
  place: Place;
  reservation_elements: ReservationElement[];
  shares: ShareExtended[];
}

export class ReservationItemExt {
  id: number;
  place: Place;
  price: number;
  total: number;
  quantity: number;
  element: Element;
}

export class Reservation implements Deserializable {

  id: number;
  facility: number;
  checkin: Date;
  checkout: Date;
  period: Period;
  settled: boolean;
  total: number;
  presence: number;
  effective_presence: number;
  reservation_items: ReservationItem[];
  itemsExt: ReservationItemExt[];
  checked_in: boolean;
  sharing: boolean;
  shared_on: number;
  offline: boolean;
  voucher: string;
  bx_points: number;
  fee: number;
  user_copy: User;
  seasonal: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    if (this.reservation_items) {
      this.reservation_items = this.reservation_items.map(item => new ReservationItem().deserialize(item))
    }
    return this;
  }

  getReservationPeriod(): string {
    if (this.period === "MOR") {
      return "Mattina";
    } else if (this.period === "AFT") {
      return "Pomeriggio";
    } else if (this.period === "DAY") {
      return "Tutto il giorno";
    }
  }

  get hasPlacesToBeAssigned() {
    let count = 0;
    for (let item of this.reservation_items) {
      if (item.place == null) {
        count++;
      }
    }
    return count != 0;
  }

  buildSummary() {
    this.itemsExt = [];
    for (let item of this.reservation_items) {
      // if (item.place != null && item.place.shadow_element != null) {
      // 	var shadowElement = new ReservationItemExt();
      // 	shadowElement.id = item.id;
      // 	shadowElement.place = item.place
      // 	shadowElement.price = 1;
      // 	shadowElement.total = 1;
      // 	shadowElement.quantity = 1;
      // 	shadowElement.element = item.place.shadow_element;
      // 	this.itemsExt.push(shadowElement);
      // }
      for (let el of item.reservation_elements) {
        var itemExt = new ReservationItemExt();
        itemExt.id = item.id;
        itemExt.place = item.place
        itemExt.price = el.price;
        itemExt.total = el.price_total;
        itemExt.quantity = el.quantity;
        itemExt.element = el.element_type;
        this.itemsExt.push(itemExt);
      }
    }
  }
}

export class ReservationExt extends Reservation {
  checkin_formatted?: string;
  checkout_formatted?: string;
}

export interface User {
  email: string;
  first_name: string;
  last_name: string;
  phone?: string;
}

export class ReservationTotal {
  total: string;
  sub_total: string;
  fee: string;
  total_discounted: string;
  promo_value: string;
  bx_points: number;
}

export interface SeasonalsResponse {
  count: number;
  next: number;
  previous: number;
  results: Seasonal[];
}


export interface Seasonal {
  id: number;
  last_login?: string;
  is_superuser?: boolean;
  email: string;
  first_name: string;
  last_name: string;
  birth_date?: string;
  birth_town?: string;
  role: string;
  state: string;
  is_staff?: boolean;
  is_active?: boolean;
  date_joined?: string;
  phone: string;
  is_admin?: boolean;
  bx_points?: number;
  facility?: number;
  vip_facilities?: number[];
  seasonal_facilities?: number[];
  reservations?: Reservation[];
}

export interface Share {
  item: number;
  checkin: string;
  checkout: string;
}

export interface ShareExtended {
  id: number;
  reservations: Reservation[];
  checkin: string;
  checkout: string;
  settled: boolean;
  period: Period;
}

export interface ReservationCount {
  reservations_app: number;
  reservations_admin: number;
}

