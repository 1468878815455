import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Area, Level, MapPlace, MapPlaces, Period } from 'src/app/reservations-table/reservation';
import { ReservationsService } from 'src/app/reservations-table/reservation.service';
import { SpinnerService } from 'src/app/spinner/spinner.service';

const prefix = 'assets/img/';

@Component({
  selector: 'app-reservation-wizard-place-selection',
  templateUrl: './reservation-wizard-place-selection.component.html',
  styleUrls: ['./reservation-wizard-place-selection.component.scss']
})
export class ReservationWizardPlaceSelectionComponent implements OnInit {


  _areas: Area[] = null;
  _loading: boolean = false;
  _places: MapPlaces = null;
  _selectedPlace: MapPlace = null;

  set area(area: number) {
    this.service.setCurrentMapArea(area);
    this.getPlaces();
  }

  get area() {
    return this.service.getCurrentMapArea();
  }


  @Input() reservation: any;

  @Output() previous_step = new EventEmitter<any>();
  @Output() next_step = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();



  constructor(public service: ReservationsService,
    private spinnerService: SpinnerService,
    private modalService: MDBModalService) { }

  ngOnInit() {
    this.getAreas();
    this.getPlaces();
  }

  showSpinner() {
    this._loading = true;
  }

  hideSpinner() {
    this._loading = false;
  }

  async getAreas() {
    this.showSpinner();
    this._areas = await this.service.getAreas().toPromise();
    if (this.area == null && this._areas.length > 0) {
      this.area = this._areas[0].id;
    }
    this.hideSpinner();
  }

  async getPlaces() {
    if (this.area == null || this.reservation.checkin == null) { return; }

    this.showSpinner();
    if (this.reservation.checkout && this.reservation.checkin != this.reservation.checkout)
      this._places = await this.service.getPlacesWithDate(this.area, this.reservation.checkin, this.reservation.checkout).toPromise();
    else
      this._places = await this.service.getPlacesWithDate(this.area, this.reservation.checkin).toPromise();
    this._places.places = _.sortBy(this._places.places, ['row', 'col']);
    this.hideSpinner();
  }

  getPlace(row: number, col: number): MapPlace {
    return this._places.places[row * this._places.col + col];
  }

  counter(i: number) {
    return new Array(i);
  }

  getAreaNameFromId(id: number) {
    return this._areas.find(area => area.id == id).name;
  }

  public selectPlace(place: MapPlace) {
    if (place.reservation == null || place.reservation.length == 0)
      this._selectedPlace = place;
    else
      this._selectedPlace = null;
  }

  getMorningImage(place: MapPlace): string {
    if (place.reservation == null) {
      return 'assets/img/mattina_vendibile.png';
    }
    for (const reservation of place.reservation) {
      if (reservation.period == Period.MOR) {
        if (reservation.offline == false && !reservation.checked_in) {
          return 'assets/img/mattina_online.svg';
        } else if (reservation.offline == false && reservation.checked_in) {
          return 'assets/img/mattina_checkin_online.svg';
        } else if (reservation.offline == true && !reservation.checked_in) {
          return 'assets/img/mattina_offline.svg';
        } else if (reservation.offline == true && reservation.checked_in) {
          return 'assets/img/mattina_checkin_offline.svg';
        }
      } else if (reservation.period == Period.DAY) {
        if (reservation.offline == false && !reservation.checked_in) {
          return 'assets/img/mattina_online-pieno.svg';
        } else if (reservation.offline == false && reservation.checked_in) {
          return 'assets/img/mattina_checkin_online-pieno.png';
        } else if (reservation.offline == true && !reservation.checked_in) {
          return 'assets/img/mattina_offline-pieno.svg';
        } else if (reservation.offline == true && reservation.checked_in) {
          return 'assets/img/mattina_checkin_offline-pieno.png';
        }
      }
    }
    return 'assets/img/mattina_vendibile.png';
  }

  getAfternoonImage(place: MapPlace): string {
    if (place.reservation == null) {
      return 'assets/img/sera_vendibile.png';
    }
    for (const reservation of place.reservation) {
      if (reservation.period == Period.AFT) {
        if (reservation.offline == false && !reservation.checked_in) {
          return 'assets/img/sera_online.svg';
        } else if (reservation.offline == false && reservation.checked_in) {
          return 'assets/img/sera_checkin_online.svg';
        } else if (reservation.offline == true && !reservation.checked_in) {
          return 'assets/img/sera_offline.svg';
        } else if (reservation.offline == true && reservation.checked_in) {
          return 'assets/img/sera_checkin_offline.svg';
        }
      } else if (reservation.period == Period.DAY) {
        if (reservation.offline == false && !reservation.checked_in) {
          return 'assets/img/sera_online-pieno.svg';
        } else if (reservation.offline == false && reservation.checked_in) {
          return 'assets/img/sera_checkin_online-pieno.png';
        } else if (reservation.offline == true && !reservation.checked_in) {
          return 'assets/img/sera_offline-pieno.svg';
        } else if (reservation.offline == true && reservation.checked_in) {
          return 'assets/img/sera_checkin_offline-pieno.png';
        }
      }
    }
    return 'assets/img/sera_vendibile.png';
  }

  getMorningShadowElement(place: MapPlace): string {
    if (place.shadow_element == null) {
      return 'assets/img/mappa_disabilitato.svg';
    }
    if (place.shadow_element.keyword == 'gazebo') {
      return this.getMorningGazebo(place);
    } else if (place.shadow_element.keyword == 'umbrella') {
      return this.getMorningOmbrellone(place);
    }
  }

  getAfternoonShadowElement(place: MapPlace): string {
    if (place.shadow_element == null) {
      return 'assets/img/mappa_disabilitato.svg';
    }
    if (place.shadow_element.keyword == 'gazebo') {
      return this.getAfternoonGazebo(place);
    } else if (place.shadow_element.keyword == 'umbrella') {
      return this.getAfternoonOmbrellone(place);
    }
  }

  getMorningGazebo(place: MapPlace): string {
    if (place.reservation == null) {
      return prefix + 'gazebo_vendibile_pieno_mattina.png';
    }
    for (const reservation of place.reservation) {
      if (reservation.period == Period.MOR) {
        return prefix + 'gazebo_prenotato_mattina.png';
      } else if (reservation.period == Period.DAY) {
        return prefix + 'gazebo_prenotato_pieno_mattina.png';
      }
    }
    return prefix + 'gazebo_vendibile_mattina.png';
  }



  getAfternoonGazebo(place: MapPlace): string {
    if (place.reservation == null) {
      return prefix + 'gazebo_vendibile_pieno_sera.png';
    }
    for (const reservation of place.reservation) {
      if (reservation.period == Period.AFT) {
        return prefix + 'gazebo_prenotato_sera.png';
      } else if (reservation.period == Period.DAY) {
        return prefix + 'gazebo_prenotato_pieno_sera.png';
      }
    }
    return prefix + 'gazebo_vendibile_sera.png';
  }

  getMorningOmbrellone(place: MapPlace): string {
    if (place.reservation == null) {
      return prefix + 'ombrellone_vendibile_pieno_mattina.png';
    }
    for (const reservation of place.reservation) {
      if (reservation.period == Period.MOR) {
        return prefix + 'ombrellone_prenotato_mattina.png';
      } else if (reservation.period == Period.DAY) {
        return prefix + 'ombrellone_prenotato_pieno_mattina.png';
      }
    }
    return prefix + 'ombrellone_vendibile_mattina.png';
  }

  getAfternoonOmbrellone(place: MapPlace): string {
    if (place.reservation == null) {
      return prefix + 'ombrellone_vendibile_pieno_sera.png';
    }
    for (const reservation of place.reservation) {
      if (reservation.period == Period.AFT) {
        return prefix + 'ombrellone_prenotato_sera.png';
      } else if (reservation.period == Period.DAY) {
        return prefix + 'ombrellone_prenotato_pieno_sera.png';
      }
    }
    return prefix + 'ombrellone_vendibile_sera.png';
  }


  isSharedPlace(place: MapPlace): boolean {
    if (place.reservation == null) { return false; }
    for (const reservation of place.reservation) {
      if (reservation.shared_on != null) { return true; }
    }
  }

  getPlaceLevelImage(place: MapPlace): string {
    if (place.level === Level.NRM) {
      return 'assets/img/stella_basic.png';
    } else if (place.level === Level.SLV) {
      return 'assets/img/stella_gold.svg';
    } else if (place.level === Level.GLD) {
      return 'assets/img/stella_platinum.svg';
    }
  }

  goToPreviousStep() {
    this.previous_step.emit(this.reservation);
  }

  goToNextStep() {
    this.reservation.place = this._selectedPlace.id;
    this.reservation.place_object = this._selectedPlace;
    this.reservation.area_object = this._areas.find(area => area.id == this.area);
    this.next_step.emit(this.reservation);
  }

  closeModal() {
    this.close.emit();
  }

  getTooltip(place: MapPlace) {
    if (place.reservation == null || place.reservation.length == 0)
      return "Posto disponibile";
    else
      return "Posto prenotato";
  }

}
