import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';

@Component({
  selector: 'app-reservation-wizard-complete',
  templateUrl: './reservation-wizard-complete.component.html',
  styleUrls: ['./reservation-wizard-complete.component.scss']
})
export class ReservationWizardCompleteComponent implements OnInit {

  reservation: any;
  step_selected: number;

  constructor(public modalRef: MDBModalRef, private modalService: MDBModalService) { }

  ngOnInit() {
  }

  closeModal(event, finished?: boolean) {
    if (finished)
      this.modalService.config.data['finished'] = true;
    else
      this.modalService.config.data['finished'] = false;
    this.modalRef.hide();
  }

  openFirstModal(event) {
    this.modalRef.hide();
    this.modalService.show(ReservationWizardCompleteComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      data: {
        reservation: this.reservation,
        step_selected: 1
      }
    })
  }


  openSecondModal(event) {
    this.modalRef.hide();
    this.modalService.show(ReservationWizardCompleteComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      data: {
        reservation: this.reservation,
        step_selected: 2
      }
    })
  }

  openThirdModal(event) {
    this.modalRef.hide();
    if (this.reservation.make_seasonal){
      this.modalService.show(ReservationWizardCompleteComponent, {
        ignoreBackdropClick: true,
        class: 'modal-lg',
        data: {
          reservation: this.reservation,
          step_selected: this.reservation.free_place || (!this.reservation.free_place && this.reservation.place) ? 4 : 6
        }
      })
    }else {
      this.modalService.show(ReservationWizardCompleteComponent, {
        ignoreBackdropClick: true,
        class: 'modal-lg',
        data: {
          reservation: this.reservation,
          step_selected: this.reservation.free_place || (!this.reservation.free_place && this.reservation.place) ? 3 : 6
        }
      })
    }
  }

  openFourthModal(event) {
    this.modalRef.hide();
    this.modalService.show(ReservationWizardCompleteComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      data: {
        reservation: this.reservation,
        step_selected: 4
      }
    })
  }

  openFifthModal(event) {
    this.modalRef.hide();
    this.modalService.show(ReservationWizardCompleteComponent, {
      ignoreBackdropClick: true,
      class: 'modal-md',
      data: {
        reservation: this.reservation,
        step_selected: 5
      }
    })
  }

}
