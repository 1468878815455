import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalService } from 'angular-bootstrap-md';
import * as moment from 'moment';
import { DaterangepickerComponent, DaterangepickerDirective, LocaleConfig } from 'ngx-daterangepicker-material';
import { AreaMapComponent } from '../area-map/area-map.component';
import { Facility } from '../auth';
import { AuthService } from '../auth.service';
import { ReservationCount } from '../reservations-table/reservation';
import { ReservationsService } from '../reservations-table/reservation.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  facility: Facility;
  selected: any;
  selected_date: Date = new Date();

  locale: LocaleConfig = {
    applyLabel: 'Applica',
    customRangeLabel: ' - ',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek(),
    format: 'DD/MM/YYYY'
  }

  count: ReservationCount;
  refresh: boolean;

  @ViewChild(DaterangepickerComponent, { static: false }) datePicker: DaterangepickerComponent;
  @ViewChild(DaterangepickerDirective, { static: false }) picker: DaterangepickerDirective;

  @ViewChild(AreaMapComponent, { static: true }) map: AreaMapComponent;

  constructor(
    public service: AuthService, private router: Router,
    private reservation_service: ReservationsService,
    private modalService: MDBModalService,
  ) {
    this.modalService.closed.subscribe(() => {
      if (this.modalService.config.data['finished']) {
        this.refresh = true;
        this.refreshCount();
      }
    })
  }

  ngOnInit() {
    this.selected = {
      startDate: moment(this.selected_date),
      endDate: moment(this.selected_date),
    };
    this.prefetchData();
  }

  async prefetchData() {
    let facility = await this.service.getFacilityRemote().toPromise();
    this.service.resetFacility(facility);
    this.facility = this.service.getFacility();
    this.refreshCount();
  }

  async refreshCount() {
    this.count = await this.reservation_service.getReservationCount(moment(this.selected_date).format('YYYY-MM-DD')).toPromise();
  }

  choosedDate(event) {
    this.selected_date = event.startDate.toDate();
    this.refreshCount();
  }

  openDatePicker() {
    this.picker.toggle();
  }

  async changeRentableState(event) {
    this.facility.enabled = event.target.checked;
    let facility = await this.service.toggleFacilityRemote().toPromise();
    this.service.setFacility(facility);
    this.facility = this.service.getFacility();
  }


  goToWhitelist() {
    this.router.navigate(['whitelist']);
  }

  goToClosureDate() {
    this.router.navigate(['closure-date']);
  }


}
