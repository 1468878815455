import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../auth.service';
import { UserInfo, Facility, TotalizerElement } from '../auth';
import * as moment from 'moment';

@Component({
  selector: 'app-total-table',
  templateUrl: './total-table.component.html',
  styleUrls: ['./total-table.component.scss']
})
export class TotalTableComponent implements OnInit {

  constructor(public service: AuthService) { }

  _date: Date;
  date_string: string;
  loading: boolean = false;
  elements: TotalizerElement[];
  private _refresh: boolean;
  public get refresh(): boolean {
    return this._refresh;
  }
  @Input()
  public set refresh(value: boolean) {
    this._refresh = value;
    if (value == true){
      this.refreshData();
      this.refreshed.emit();
    }
      
  }


  @Output() refreshed = new EventEmitter<any>();


  ngOnInit() {
    this.syncData()
  }

  @Input()
  public set date(date: Date) {
    this._date = date;
    this.syncData();
  }

  public get date() {
    return this._date;
  }

  async syncData() {
    const years = moment(this._date).year();
    const months = moment(this._date).month() + 1;
    const days = moment(this._date).date();

    this.date_string = years + '-' + months + '-' + days;

    await this.refreshData();
  }

  async refreshData() {
    this.elements = await this.service.getTotalizerRemote(this.date_string).toPromise();
  }

}

