import { DecimalPipe } from '@angular/common';
import { Component, QueryList, ViewChildren, Input, ViewChild, HostListener, ChangeDetectorRef, OnInit, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Transaction, TransactionExt } from '../transactions';
import { TransactionsService } from '../transactions.service';
import { NgbdSortableHeader, SortEvent } from '../reservations-table/sortable.directive';

import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from '../spinner/spinner.service';

import * as moment from 'moment';
import { MDBModalService, MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import { ReservationsService } from '../reservations-table/reservation.service';
import { ReservationDetailModalComponent } from '../modals/reservation-detail/reservation-detail-modal.component';
import { DaterangepickerComponent, DaterangepickerDirective, LocaleConfig } from 'ngx-daterangepicker-material';

@Component({
  selector: 'app-wallet-table',
  templateUrl: './wallet-table.component.html',
  styleUrls: ['./wallet-table.component.scss'],
  providers: [TransactionsService, DecimalPipe, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class WalletTableComponent implements OnInit, AfterViewInit {

  @Input() facility_name: string;

  sort_text: string;

  total$: Observable<number>;
  total_payed$: Observable<number>;
  amount$: Observable<number>;
  payed_status: boolean;

  locale: LocaleConfig = {
    applyLabel: 'Applica',
    customRangeLabel: ' - ',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek(),
    format: 'DD/MM/YYYY'
  }

  @ViewChildren(DaterangepickerDirective) datePickers: QueryList<DaterangepickerDirective>;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  headElements = ['N° PRENOTAZIONE', 'DATA PRENOTAZIONE', 'VOUCHER', 'DATA TRANSAZIONE', 'IMPORTO', 'BX FEE', 'RICAVO NETTO', 'DETTAGLIO'];
  searchText: string = '';
  previous: string;
  number_pages: number;
  pages: number[];
  max_element_per_page: number = 5;
  private _transactions: TransactionExt[];
  public get transactions(): TransactionExt[] {
    return this._transactions;
  }
  @Input()
  public set transactions(value: TransactionExt[]) {
    value.forEach(r => {
      r.checkin_formatted = moment(r.checkin).format('DD/MM/YYYY');
      r.checkout_formatted = moment(r.checkout).format('DD/MM/YYYY');
      r.date_formatted = moment(r.date).format('DD/MM/YYYY');
    });
    this._transactions = value;
    this.mdbTable.setDataSource(value);
    this.previous = this.mdbTable.getDataSource();
    this.calculatePages();
  }


  private _fromDatePicker: any;
  public get fromDatePicker(): any {
    return this._fromDatePicker;
  }
  public set fromDatePicker(value: any) {
    this._fromDatePicker = value;
    this.service.fromDate = value.startDate.toDate();
  }
  private _toDatePicker: any;
  public get toDatePicker(): any {
    return this._toDatePicker;
  }
  public set toDatePicker(value: any) {
    this._toDatePicker = value;
    this.service.toDate = value.endDate.toDate();
  }

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;


  constructor(
    public service: TransactionsService,
    private spinnerService: SpinnerService,
    private cdRef: ChangeDetectorRef,
    public reservation_service: ReservationsService,
    private modalService: MDBModalService) {
    this.total$ = service.total$;
    this.total_payed$ = service.total_payed$;
    this.amount$ = service.amount$;
    this.selectLastMonth();

    this.service.loading$.subscribe((_x: boolean) => {
      if (_x == true) {
        this.spinnerService.startLoading();
      } else {
        this.spinnerService.stopLoading();
      }
    })
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.direction == 'asc') {
        this.sort_text = '↓ N° PRENOTAZIONE'
      }
      if (header.direction == 'desc') {
        this.sort_text = '↑ N° PRENOTAZIONE'
      }
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  selectLastWeek() {
    this.fromDatePicker = {
      startDate: moment(new Date()).startOf('week').startOf('day'),
      endDate: moment(new Date()).startOf('week').startOf('day'),
    }
    this.toDatePicker = {
      startDate: moment(new Date()).startOf('day'),
      endDate: moment(new Date()).endOf('day'),
    }
  }

  selectLastMonth() {
    this.fromDatePicker = {
      startDate: moment(new Date()).startOf('month').startOf('day'),
      endDate: moment(new Date()).startOf('month').endOf('day'),
    }
    this.toDatePicker = {
      startDate: moment(new Date()).startOf('day'),
      endDate: moment(new Date()).startOf('day'),
    }
  }

  selectPayed() {
    this.payed_status = !this.service.payed;
    this.service.payed = this.payed_status;
    this.sort_text = "↑ N° PRENOTAZIONE";
    this.service.sortDirection = 'desc'
  }

  previousPage() {
    this.service.prev();
  }

  nextPage() {
    this.service.next();
  }

  ngOnInit() {
    this.fromDatePicker = {
      startDate: moment(this.service.fromDate).startOf("day"),
      endDate: moment(this.service.fromDate).endOf("day"),
    }
    this.toDatePicker = {
      startDate: moment(this.service.toDate).startOf("day"),
      endDate: moment(this.service.toDate).endOf("day"),
    }
    this.service.transactions$.subscribe(transactions => this.transactions = transactions);
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.max_element_per_page);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }


  calculatePages() {
    this.number_pages = Math.floor(this.transactions.length / this.mdbTablePagination.maxVisibleItems);
    let mod = Math.floor(this.transactions.length % this.mdbTablePagination.maxVisibleItems);
    if (mod > 0 || this.number_pages == 0)
      this.number_pages++;
    this.pages = [];
    for (let i = 1; i <= this.number_pages; i++) {
      this.pages.push(i);
    }
  }


  @HostListener('input') oninput() {
    this.searchItems();
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
      this.mdbTable.setDataSource(prev);
      this.transactions = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.transactions = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
      this.mdbTable.setDataSource(prev);
    }

  }

  goToPage(page: number) {
    let diff = page - this.mdbTablePagination.activePageNumber;
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        this.mdbTablePagination.nextPage();
      }
    }
    if (diff < 0) {
      for (let i = 0; i < Math.abs(diff); i++) {
        this.mdbTablePagination.previousPage();
      }
    }

  }


  goToReservationDetail(reservation_id: number) {
    this.reservation_service.getReservation(reservation_id).subscribe(
      reservation => {
        this.modalService.show(ReservationDetailModalComponent, {
          ignoreBackdropClick: true,
          class: 'modal-lg modal-detail',
          data: {
            reservation: reservation,
          }
        })
      }
    )
  }

  calculateSubTotalRevenue() {
    let revenue: number = 0;
    this.transactions.forEach(t => revenue += t.revenue / 100);
    return revenue;
  }

  openFromDatePicker() {
    this.datePickers.first.toggle();
  }

  openToDatePicker() {
    this.datePickers.last.toggle();
  }

}

