import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import * as moment from 'moment';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
moment.locale('it')


@Component({
  selector: 'app-reservation-wizard-first',
  templateUrl: './reservation-wizard-first.component.html',
  styleUrls: ['./reservation-wizard-first.component.scss']
})
export class ReservationWizardFirstComponent implements OnInit, AfterViewInit {

  date_start: string;
  date_end: string;
  @Input() reservation: any;
  selected: any;

  locale: LocaleConfig = {
    applyLabel: 'Applica',
    customRangeLabel: ' - ',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek(),
  }

  @Output() previous_step = new EventEmitter<any>();
  @Output() next_step = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();

  @ViewChild(DaterangepickerComponent, { static: false }) datePicker: DaterangepickerComponent;

  constructor() { }

  ngOnInit() {
    this.locale = {
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
    }
  }

  ngAfterViewInit() {
    if (this.reservation.checkin && this.reservation.checkout) {
      this.selected = {
        startDate: moment(this.reservation.checkin),
        endDate: moment(this.reservation.checkout),
      };
      this.date_start = this.reservation.checkin;
      this.date_end = this.reservation.checkout;
      this.datePicker.setStartDate(moment(this.reservation.checkin));
      this.datePicker.setEndDate(moment(this.reservation.checkout));
      this.datePicker.updateCalendars();
    } else {
      this.selected = {
        startDate: moment(new Date()),
        endDate: moment(new Date()),
      }
      this.date_start = moment(new Date()).format('YYYY-MM-DD');
      this.date_end = moment(new Date()).format('YYYY-MM-DD');
    }
  }

  choosedDate(event) {
    this.date_start = moment(event.startDate).format('YYYY-MM-DD');
    this.date_end = moment(event.endDate).format('YYYY-MM-DD');
  }

  changeStartDate(event) {
    this.date_start = moment(event.startDate).format('YYYY-MM-DD');
    this.date_end = moment(event.startDate).format('YYYY-MM-DD');
  }



  goToPreviousStep() {
    this.previous_step.emit(this.reservation);
  }

  goToNextStep() {
    this.reservation.checkin = this.date_start;
    this.reservation.checkout = this.date_end;
    this.next_step.emit(this.reservation);
  }

  closeModal() {
    this.close.emit();
  }

}
