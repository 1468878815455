import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfo, Facility } from '../auth';
import { AuthService } from '../auth.service';
@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {

  userInfo: UserInfo = null;
  facility: Facility = null;

  constructor(public authService: AuthService, public router: Router, private changeDetector: ChangeDetectorRef) {
    // this.service.userlogin.subscribe(res => this.setUserInfo(res));
  }

  ngOnInit() {
    this.setUserInfo(this.authService.getUserInfo());
    this.setFacility(this.authService.getFacility());
    this.authService.userLoggedIn.subscribe(res => this.setUserInfo(res));
    this.authService.userLoggedOut.subscribe(res => this.setUserInfo(null) );
  }

  setUserInfo(usr : UserInfo) {
    this.userInfo = usr;
  }

  setFacility(fac : Facility) {
    this.facility = fac;
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

}
