import { ClosureDateComponent } from './closure-date/closure-date.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './account/account.component';
import { WhitelistComponent } from './whitelist/whitelist.component';
import { SupportComponent } from './support/support.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { OverviewComponent } from './overview/overview.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { ExperiencesComponent } from './experiences/experiences.component';
import { WalletComponent } from './wallet/wallet.component';
import { BeachComponent } from './beach/beach.component';
import { ReservationDetailComponent } from './reservation-detail/reservation-detail.component';
import { ListinoComponent } from './listino/listino.component';
import { LoginComponent } from './login/login.component';
import { PricesTableComponent } from './prices-table/prices-table.component';
import { AuthGuardService } from './auth-guard.service';
import { SeasonalPageComponent } from './seasonal/seasonal-page/seasonal-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OnboardingComponent } from './onboarding/onboarding.component';

const routes: Routes = [

  { path: "", pathMatch: "full", redirectTo: "login" },
  { path: 'login', component: LoginComponent },
  { path: 'onboarding', component: OnboardingComponent },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuardService] },
  { path: 'whitelist', component: WhitelistComponent, canActivate: [AuthGuardService] },
  { path: 'support', component: SupportComponent, canActivate: [AuthGuardService] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuardService] },
  { path: 'overview', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'reservations', component: ReservationsComponent, canActivate: [AuthGuardService] },
  { path: 'experiences', component: ExperiencesComponent, canActivate: [AuthGuardService] },
  { path: 'listino', component: ListinoComponent, canActivate: [AuthGuardService] },
  { path: 'wallet', component: WalletComponent, canActivate: [AuthGuardService] },
  { path: 'closure-date', component: ClosureDateComponent, canActivate: [AuthGuardService] },
  { path: 'beach', component: BeachComponent, canActivate: [AuthGuardService] },
  { path: 'reservation-detail/:reservation_id', component: ReservationDetailComponent, canActivate: [AuthGuardService] },
  { path: 'season-detail/:season_id', component: PricesTableComponent, canActivate: [AuthGuardService] },
  { path: 'season-detail', component: PricesTableComponent, canActivate: [AuthGuardService] },
  { path: 'seasonal', component: SeasonalPageComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
