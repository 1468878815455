import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { AuthService } from 'src/app/auth.service';
import { Seasonal } from 'src/app/reservations-table/reservation';
import { ReservationsService } from 'src/app/reservations-table/reservation.service';
import { SpinnerService } from 'src/app/spinner/spinner.service';

@Component({
  selector: 'app-seasonal-modal-form',
  templateUrl: './seasonal-modal-form.component.html',
  styleUrls: ['./seasonal-modal-form.component.scss']
})
export class SeasonalModalFormComponent implements OnInit {

  seasonal: Seasonal;

  constructor(
    private service: ReservationsService,
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private authService: AuthService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    if (!this.seasonal)
      this.seasonal = {
        id: null,
        email: null,
        first_name: null,
        last_name: null,
        role: null,
        state: null,
        phone: null,
        seasonal_facilities: null
      }
  }

  closeModal(finished?: boolean) {
    if (finished)
      this.modalService.config.data['seasonal_created'] = true;
    else
      this.modalService.config.data['seasonal_created'] = false;
    this.modalRef.hide();
  }

  isFormValid() {
    let form_valid: boolean = true;
    if (!this.seasonal.first_name || !this.seasonal.last_name || !this.seasonal.email || !this.seasonal.phone)
      form_valid = false;
    return form_valid;
  }

  submit() {
    this.seasonal.role = "SE";
    this.seasonal.state = "CON";
    this.seasonal.seasonal_facilities = [this.authService.getUserInfo().facility];
    this.spinnerService.startLoading();
    this.service.makeSeasonal(this.seasonal).subscribe(result => {
      this.closeModal(true);
      this.modalService.close.emit(this.seasonal);
    },
      error => console.log(error)
    )
    this.spinnerService.stopLoading();
  }

}
