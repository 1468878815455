import { Injectable, PipeTransform, EventEmitter } from '@angular/core';

import { LOCALE_ID, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { Reservation, SeasonElement, AssignPlaceRequest, NewReservation, ReservationItemExt, MapPlaces, MapPlace, Area, Period, Season, SeasonPeriod, ReservationTotal, Seasonal, SeasonalsResponse, Share, ShareExtended, ReservationCount } from './reservation';
import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap, map, filter, mapTo } from 'rxjs/operators';
import { SortDirection } from './sortable.directive';

import { AuthService } from '../auth.service';
import { SpinnerService } from '../spinner/spinner.service';

import * as moment from 'moment';
import { BASE_PATH } from 'src/environments/environment';
import { PresenceInfo } from '../auth';

interface SearchResult {
  reservations: Reservation[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  searchDate: Date;
  searchPlace: boolean;
  sortColumn: string;
  sortDirection: SortDirection;
}

function compare(v1, v2) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(reservations: Reservation[], column: string, direction: string): Reservation[] {
  if (direction === '') {
    return reservations;
  } else {
    return [...reservations].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(reservation: Reservation, term: string, pipe: PipeTransform) {
  return pipe.transform(reservation.id).includes(term);
}

@Injectable({ providedIn: 'root' })
export class ReservationsService {

  private reservationsUrl = BASE_PATH + '/router/reservations/';  // URL to web api
  private placesUrl = BASE_PATH + '/router/places/';
  private areasUrl = BASE_PATH + '/router/areas/';
  private listinoUrl = BASE_PATH + '/api/listino/';
  private seasonperiodUrl = BASE_PATH + '/api/season-period/';
  private elementsUrl = BASE_PATH + '/router/elements/';
  private totalUrl = BASE_PATH + '/api/reservation/total';
  private seasonalsUrl = BASE_PATH + '/router/seasonals/';
  private seasonalReservationUrl = BASE_PATH + '/api/seasonal/reservations/';
  private shareUrl = BASE_PATH + '/api/share/';
  private countlUrl = BASE_PATH + '/api/reservation/count/';

  public _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _reservations$ = new BehaviorSubject<Reservation[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private currentMapArea = null;

  public setCurrentMapArea(area: number) {
    this.currentMapArea = area;
  }

  public getCurrentMapArea() {
    return this.currentMapArea;
  }

  private _state: State = {
    page: 1,
    pageSize: 30,
    searchTerm: null,
    searchDate: null,
    searchPlace: false,
    sortColumn: '',
    sortDirection: ''
  };

  constructor(@Inject(LOCALE_ID) public locale: string, private pipe: DecimalPipe, private authService: AuthService, private spinnerService: SpinnerService, private http: HttpClient) {
    this.search();
    this.authService.userLoggedOut.subscribe(x => {
      this._reservations$.next([]);
      this._total$.next(0);
    });
  }

  public search() {
    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._reservations$.next(result.reservations);
      this._total$.next(result.total);
    });
    this._search$.next();
  }

  getReservations(): Observable<Reservation[]> {
    let url = this.reservationsUrl;

    let filters: string[] = [];

    if (this.searchDate != null && moment(this.searchDate, "YYYY-MM-DD", true).isValid()) {
      let years = moment(this.searchDate).year();
      let months = moment(this.searchDate).month() + 1;
      let days = moment(this.searchDate).date();
      let date_: string = years + "-" + months + "-" + days;
      filters.push("checkin=" + date_);
    }

    if (this.searchTerm != null) {
      filters.push("id=" + this.searchTerm);
    }

    filters.push("place=" + this.searchPlace);

    for (let i = 0; i < filters.length; i++) {
      if (i == 0) {
        url += "?";
      }
      else if (i > 0) {
        url += "&";
      }
      url += filters[i];
    }

    return this.http.get<Reservation[]>(url).pipe(
      map(items => items.map(item => new Reservation().deserialize(item)))
    );
  }

  deleteReservation(reservation: Reservation): Observable<any> {
    const url = `${this.reservationsUrl}${reservation.id}/`;
    return this.http.delete(url);
  }

  updateReservation(reservation: Reservation): Observable<Reservation> {
    const url = `${this.reservationsUrl}${reservation.id}/`;
    return this.http.put(url, reservation).pipe(
      map(item => new Reservation().deserialize(item))
    );
  }

  getReservation(id: number): Observable<Reservation> {
    const url = `${this.reservationsUrl}${id}/?lang=it`;
    return this.http.get<Reservation>(url).pipe(
      map(item => new Reservation().deserialize(item))
    );
  }

  makeReservation(newReservation: NewReservation): Observable<Reservation> {
    const url = `${this.reservationsUrl}`;
    return this.http.post<NewReservation>(url, newReservation).pipe(
      map(item => new Reservation().deserialize(item))
    );
  }


  getReservationTotal(newReservation: NewReservation): Observable<ReservationTotal> {
    const url = `${this.totalUrl}`;
    return this.http.post<ReservationTotal>(url, newReservation);
  }

  assignPlace(place: AssignPlaceRequest, reservation: Reservation): Observable<Reservation> {
    const url = `${this.reservationsUrl}${reservation.id}/`;
    return this.http.put<AssignPlaceRequest>(url, place).pipe(
      map(item => new Reservation().deserialize(item))
    );
  }

  modifyPlace(place: MapPlace): Observable<MapPlaces> {
    const url = `${this.placesUrl}${place.id}/`;
    return this.http.put<MapPlace>(url, place).pipe(
      map(item => new MapPlaces().deserialize(item))
    );
  }

  getPlaces(area: number): Observable<MapPlaces> {
    const url = `${this.placesUrl}?area=${area}`;
    return this.http.get<MapPlaces>(url).pipe(
      map(item => new MapPlaces().deserialize(item))
    );
  }

  getPlacesWithDate(area: number, checkin: string, checkout?: string): Observable<MapPlaces> {
    let url = `${this.placesUrl}?area=${area}&checkin=${checkin}&lang=it`;
    if (checkout)
      url = `${this.placesUrl}?area=${area}&checkin=${checkin}&${checkout}&lang=it`;
    return this.http.get<MapPlaces>(url).pipe(
      map(item => new MapPlaces().deserialize(item))
    );
  }

  getPlacesWithRangeAndPeriod(area: number, checkin: string, checkout: string, period: Period): Observable<MapPlaces> {
    const url = `${this.placesUrl}?area=${area}&checkin=${checkin}&checkout=${checkout}&period=${period}&app=true`;
    return this.http.get<MapPlaces>(url).pipe(
      map(item => new MapPlaces().deserialize(item))
    );
  }

  getPlacesWithDateAndPeriod(area: number, date: string, period: Period): Observable<MapPlaces> {
    const url = `${this.placesUrl}?area=${area}&checkin=${date}&period=${period}`;
    return this.http.get<MapPlaces>(url).pipe(
      map(item => new MapPlaces().deserialize(item))
    );
  }

  getAreas(): Observable<Area[]> {
    let facility = this.authService.getUserInfo().facility;
    let url = `${this.areasUrl}?facility=${facility}`;
    return this.http.get<Area[]>(url).pipe(
      map(items => items.map(item => new Area().deserialize(item)))
    );
  }

  getSeasonPrices(): Observable<Season[]> {
    let facility = this.authService.getUserInfo().facility;
    let url = `${this.listinoUrl}?facility=${facility}`;
    // const httpOptions = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': this.authService.getUser().access_token
    // })
    return this.http.get<Season[]>(url).pipe(
      map(items => items.map(item => new Season().deserialize(item)))
    );
  }

  getSeasonPricesCheckin(checkin: string): Observable<Season> {
    let facility = this.authService.getUserInfo().facility;
    let url = `${this.listinoUrl}?facility=${facility}&checkin=${checkin}`;
    // const httpOptions = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': this.authService.getUser().access_token
    // })
    return this.http.get<Season>(url);
  }


  getSeason(id: number): Observable<Season> {
    let url = `${this.listinoUrl}${id}/?lang=it`;
    return this.http.get<Season>(url).pipe(
      map(item => new Season().deserialize(item))
    );
  }

  setSeason(season: Season): Observable<any> {
    let url = `${this.listinoUrl}${season.id}/?lang=${this.locale}`;
    return this.http.put<Season>(url, season);
  }

  postSeasonDateRemote(dal: string, al: string, id_season_period: number): Observable<any> {
    let url = `${this.seasonperiodUrl}`;
    let data = {
      date_start: dal,
      date_end: al,
      season_period: id_season_period
    }
    return this.http.post<SeasonPeriod>(url, data);
  }

  getElements(checkin: string, checkout: string, period: Period): Observable<SeasonElement[]> {
    let facility = this.authService.getUserInfo().facility;
    const url = `${this.elementsUrl}?facility=${facility}&checkin=${checkin}&checkout=${checkout}&period=${period}&app=true`;
    return this.http.get<SeasonElement[]>(url).pipe(
      /*  delay(2000), */
      map(items => items.map(item => new SeasonElement().deserialize(item)))
    );
  }


  getSeasonals(): Observable<Seasonal[]> {
    let facility = this.authService.getUserInfo().facility;
    let url = `${this.seasonalsUrl}?facility=${facility}`;
    return this.http.get<SeasonalsResponse>(url).pipe(
      map(resp => resp.results)
    );
  }

  makeSeasonal(newSeasonal: Seasonal): Observable<Seasonal> {
    const url = `${this.seasonalsUrl}`;
    return this.http.post<Seasonal>(url, newSeasonal);
  }


  getSeasonalReservations(): Observable<Reservation[]> {
    let url = `${this.seasonalReservationUrl}?lang=it`;
    return this.http.get<Reservation[]>(url).pipe(map(items => items.map(item => new Reservation().deserialize(item))));
  }


  makeShare(share: Share): Observable<Share> {
    const url = `${this.shareUrl}`;
    return this.http.post<Share>(url, share);
  }

  deleteShare(share: ShareExtended) {
    const url = `${this.shareUrl}/?id=${share.id}`;
    return this.http.delete(url);
  }


  getReservationCount(checkin?: string, checkout?: string): Observable<ReservationCount> {
    let facility = this.authService.getUserInfo().facility;
    let url = `${this.countlUrl}${facility}/`;
    if (checkin)
      url += `?checkin=${checkin}`;
    if (checkout) {
      url += `&checkout=${checkout}`;
    }

    return this.http.get<ReservationCount>(url);

  }

  get reservations$() {
    return this._reservations$.asObservable();
  }

  get total$() {
    return this._total$.asObservable();
  }

  get loading$() {
    return this._loading$.asObservable();
  }

  get page() {
    return this._state.page;
  }

  get pageSize() {
    return this._state.pageSize;
  }

  get searchTerm() {
    return this._state.searchTerm;
  }

  get searchDate() {
    return this._state.searchDate;
  }

  get searchPlace() {
    return this._state.searchPlace;
  }

  set page(page: number) {
    this._set({ page });
  }

  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }

  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }

  set sortColumn(sortColumn: string) {
    this._set({ sortColumn });
  }

  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  set searchDate(searchDate: Date) {
    this._set({ searchDate });
  }

  set searchPlace(searchPlace: boolean) {
    this._set({ searchPlace });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private build_summary(reservation: Reservation) {
    reservation.itemsExt = [];
    for (let item of reservation.reservation_items) {
      for (let el of item.reservation_elements) {
        var itemExt = new ReservationItemExt();
        itemExt.id = item.id;
        itemExt.place = item.place
        itemExt.price = el.price;
        itemExt.total = el.price_total;
        itemExt.quantity = el.quantity;
        itemExt.element = el.element_type;
        reservation.itemsExt.push(itemExt);
      }
    }

  }

  private build_summaries(reservations: Reservation[]): Reservation[] {
    reservations.forEach(reservation => this.build_summary(reservation));
    return reservations;
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    return this.getReservations().pipe(
      map(reservations => this.build_summaries(reservations)),
      map(reservations => sort(reservations, sortColumn, sortDirection)),
      // map(reservations => reservations.filter(reservation => matches(reservation, searchTerm, this.pipe))),
      switchMap(reservations => of({ reservations: reservations, total: reservations.length })),
      switchMap(searchResult => of({ reservations: searchResult.reservations.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize), total: searchResult.reservations.length })),
    );
  }
}
