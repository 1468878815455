import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ErrorMessages, NewReservation, ReservationTotal } from 'src/app/reservations-table/reservation';
import { ReservationsService } from 'src/app/reservations-table/reservation.service';

@Component({
  selector: 'app-reservation-wizard-fourth',
  templateUrl: './reservation-wizard-fourth.component.html',
  styleUrls: ['./reservation-wizard-fourth.component.scss']
})
export class ReservationWizardFourthComponent implements OnInit {

  @Input() reservation: any;

  @Output() previous_step = new EventEmitter<any>();
  @Output() next_step = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();

  promo_code: string;
  promo_applied: boolean = false;
  error: boolean = false;
  error_message: string;

  new_reservation: NewReservation;
  reservation_total: ReservationTotal;

  constructor(private service: ReservationsService) { }

  ngOnInit() {
    this.new_reservation = new NewReservation();
    Object.assign(this.new_reservation, this.reservation);
    if (!this.reservation.make_seasonal)
      this.getReservationTotal(false);
  }

  getReservationTotal(apply_promo: boolean) {
    this.service.getReservationTotal(this.new_reservation).subscribe(ret => {
      if (apply_promo)
        this.promo_applied = true;
      this.error = false;
      this.reservation_total = ret;
    },
      error => {
        this.error = true;
        if (error.error.detail) {
          let error_code: string = error.error.detail;
          this.error_message = ErrorMessages[error_code];
        }

        else
          this.error_message = "Errore";
      }
    )
  }

  goToPreviousStep() {
    this.previous_step.emit(this.reservation);
  }

  goToNextStep() {
    this.reservation.promo_code = this.promo_code;
    this.next_step.emit(this.reservation);
  }

  closeModal() {
    this.close.emit();
  }

  applyPromoCode() {
    this.new_reservation.promo_code = this.promo_code;
    this.getReservationTotal(true);
  }


}
