import { Component, LOCALE_ID, Inject, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, Event } from '@angular/router';
import { map } from 'rxjs/operators';
import { DEVEL_STR } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = DEVEL_STR + 'BXE';
  enableMenu: boolean = true;

  languageList = [
    { code: 'en', label: 'English' },
    { code: 'it', label: 'Italian' }
  ];

  constructor(@Inject(LOCALE_ID) protected localeId: string, private router: Router, private titleService: Title) {

    titleService.setTitle(this.title);

    this.router.events.subscribe(event => {
      // if (event instanceof NavigationEnd || event instanceof NavigationStart) {
      if (this.router.url === '/login' || this.router.url === '/onboarding' ) {
        this.enableMenu = false;
      } else {
        this.enableMenu = true;
      }
      // }
    });
  }

  ngOnInit() {
  }

}
