import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { UserInfo, Facility } from '../auth';
import * as moment from 'moment';

@Component({
  selector: 'app-closure-date',
  templateUrl: './closure-date.component.html',
  styleUrls: ['./closure-date.component.scss']
})
export class ClosureDateComponent implements OnInit {

  constructor(public service: AuthService) { }

  fromDate: Date = null;
  toDate: Date = null;
  search_users: UserInfo[];
  keyword = 'email';
  loading: boolean = false;
  facility: Facility = null;

  ngOnInit() {
    this.syncData()
  }

  async syncData() {
    let facility = await this.service.getFacilityRemote().toPromise();
    this.service.resetFacility(facility);
    this.facility = this.service.getFacility();

  }

  onClear() {
    this.fromDate = null;
    this.toDate = null;
    // do something with selected item
  }

  addToClosureDate() {
    console.log("do save", this.fromDate, this.toDate);
    this.postClosureDate()
  }



  async postClosureDate(closure: number = null, remove: Boolean = false) {
    try {
      if (closure && remove) {
        await this.service.removeClosureDateRemote(closure, this.facility.id).toPromise()
      } else if (this.fromDate && this.toDate) {

        const f_years = moment(this.fromDate).year();
        const f_months = moment(this.fromDate).month();
        const f_days = moment(this.fromDate).date();

        const f_date: string = f_years + '-' + f_months + '-' + f_days;

        const t_years = moment(this.toDate).year();
        const t_months = moment(this.toDate).month();
        const t_days = moment(this.toDate).date();

        const t_date: string = t_years + '-' + t_months + '-' + t_days;

        await this.service.postClosureDateRemote(f_date, t_date, this.facility.id).toPromise()
      } else {

      }
      await this.syncData()
    } catch (error) {
      console.log('Bad post', error)
    }
  }

  removeFromClosureDate(closure) {
    console.log("do remove", closure);
    this.postClosureDate(closure, true)
  }

}
