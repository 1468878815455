import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

import { ToastService } from './toast-service';

import { NgModule } from '@angular/core';
import { Login } from '../auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public authService: AuthService, private router: Router, public toast: ToastService) {
    document.body.style.height = "100%";
    let htmlElement = document.querySelector("html");
    htmlElement.style.height = "100%";
  }

  user_error: boolean = false;
  server_error: boolean = false;
  loading: boolean = false;
  username: string = "";
  password: string = "";
  rememberme: boolean = false;

  ngOnInit(): void {
    if (this.authService.getUser() != null) {
      this.router.navigateByUrl('/overview');
    }
    let rememberedUser = this.authService.getRememberedUser();
    this.username = rememberedUser;
  }

  ngOnDestroy() {
    document.body.style.height = "";
    let htmlElement = document.querySelector("html");
    htmlElement.style.height = "";
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }

  isLoading(): boolean {
    return this.loading;
  }

  async login() {
    if (this.rememberme) {
      this.authService.rememberUser(this.username);
    }
    try {
      this.startLoading();
      let token = await this.authService.login(this.username, this.password).toPromise();
      this.authService.setToken(token);
      let userInfo = await this.authService.getUserInfoRemote().toPromise();
      this.authService.setUserInfo(userInfo);
      let facility = await this.authService.getFacilityRemote().toPromise();
      this.authService.setFacility(facility);
      this.stopLoading();
      if (userInfo.state == 'ONB')
        this.router.navigateByUrl('/onboarding');
      else
        this.router.navigateByUrl('/overview');
    } catch (error) {
      if (error.status == 500) {
        this.server_error = true;
      } else {
        this.user_error = true;
      }
      console.log(error.error.error_description);
      this.stopLoading();
      setTimeout(function () {
        this.user_error = false;
        this.server_error = false;
      }.bind(this), 3000);
    }

    // this.authService.login(this.username, this.password).toPromise().then(user => {
    //   this.authService.setUser(user);
    //   this.authService.getUserInfoRemote().toPromise().then(userInfo => {
    //     this.authService.setUserInfo(userInfo);
    //     this.stopLoading();
    //     this.router.navigateByUrl('/overview');
    //   }).catch(error => {
    //     this.stopLoading();
    //   })
    // }).catch(error => {
    //   this.stopLoading();
    // })

  }

}
