import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import * as moment from 'moment';
import { DaterangepickerComponent, DaterangepickerDirective, LocaleConfig } from 'ngx-daterangepicker-material';
import { Reservation, Share, ShareExtended } from 'src/app/reservations-table/reservation';
import { ReservationsService } from 'src/app/reservations-table/reservation.service';
import { SpinnerService } from 'src/app/spinner/spinner.service';
moment.locale('it')

@Component({
  selector: 'app-reservation-share',
  templateUrl: './reservation-share.component.html',
  styleUrls: ['./reservation-share.component.scss']
})
export class ReservationShareComponent implements OnInit {

  locale: LocaleConfig = {
    applyLabel: 'Applica',
    customRangeLabel: ' - ',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek(),
  }

  checkin: moment.Moment;
  checkout: moment.Moment;

  selected: any;
  shared_date_checkin: string;
  shared_date_checkout: string;
  show_input_picker: boolean;
  add_share_error_message: string;
  delete_share_error_message: string;


  @Input() reservation: Reservation;


  @ViewChild(DaterangepickerComponent, { static: false }) datePicker: DaterangepickerComponent;
  @ViewChild(DaterangepickerDirective, { static: false }) picker: DaterangepickerDirective;

  constructor(
    public modalRef: MDBModalRef,
    private spinnerService: SpinnerService,
    private service: ReservationsService
  ) { }

  ngOnInit() {
    this.checkin = moment(this.reservation.checkin, 'YYYY-MM-DD');
    this.checkout = moment(this.reservation.checkout, 'YYYY-MM-DD');
    this.showPickerInput();
  }
  isReservationSharable() {
    let range_days = this.checkout.diff(this.checkin, 'days');
    return this.reservation.reservation_items[0].place && this.reservation.reservation_items[0].place.eligible && range_days > 29;
  }

  showPickerInput() {
    this.show_input_picker = true;
  }

  openDatePicker() {
    this.picker.open();
  }

  isInvalidDate = (m: moment.Moment) => {
    return m < this.checkin || m > this.checkout;
  }

  submitShare() {
    let shareToSubmit: Share = {
      item: this.reservation.reservation_items[0].id,
      checkin: this.shared_date_checkin,
      checkout: this.shared_date_checkout,
    }
    this.spinnerService.startLoading();
    this.service.makeShare(shareToSubmit).subscribe(async result => {
      this.selected = null;
      this.add_share_error_message = null;
      await this.refresh();
      this.spinnerService.stopLoading();
    },
      error => {
        if (error.error[0])
          this.add_share_error_message = error.error[0];
        else
          this.add_share_error_message = 'Errore server';
        console.log(error);
        this.spinnerService.stopLoading();
      }
    )

  }

  deleteShare(share: ShareExtended) {
    this.spinnerService.startLoading();
    this.service.deleteShare(share).subscribe(async result => {
      this.delete_share_error_message = null;
      await this.refresh();
      this.spinnerService.stopLoading();
    },
      error => {
        if (error.error.detail)
          this.delete_share_error_message = error.error.detail;
        else
          this.delete_share_error_message = 'Errore server';
        console.log(error);
        this.spinnerService.stopLoading();
      }
    )
  }

  choosedDate(event) {
    this.shared_date_checkin = moment(event.startDate).format('YYYY-MM-DD');
    this.shared_date_checkout = moment(event.endDate).format('YYYY-MM-DD');
  }


  closeModal() {
    this.modalRef.hide();
  }

  async refresh() {
    this.reservation = await this.service.getReservation(this.reservation.id).toPromise();
  }


}
