import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import * as moment from 'moment';

const CRADIUS = 5;

@Component({
  selector: 'app-hz-datepicker',
  templateUrl: './hz-datepicker.component.html',
  styleUrls: ['./hz-datepicker.component.scss']
})
export class HzDatepickerComponent implements OnInit {

  selectedDate: Date = new Date();
  calendarRange: Date[] = new Array();
  today: Date = new Date();

  @Output() onDateChange = new EventEmitter<Date>();

  constructor() {
    this.updateCalendarRange();
  }

  ngOnInit() {

  }

  getMonth(date: Date) {
    moment.locale('it');
    return moment(date).format('MMM');
  }

  getMonthFull(date: Date) {
    moment.locale('it');
    return moment(date).format('MMMM');
  }

  updateCalendarRange(): void {
    this.calendarRange.splice(0, this.calendarRange.length);
    for (let i = -CRADIUS; i <= CRADIUS; ++i) {
      var newDate = moment(this.selectedDate).add(i, 'days').toDate();
      this.calendarRange.push(newDate);
    }
    this.onDateChange.emit(this.selectedDate);
  }

  selectDay(day: Date): void {
    this.selectedDate = day;
    this.updateCalendarRange();
  }

  nextDay(): void {
    this.selectedDate = moment(this.selectedDate).add(1, 'days').toDate();
    this.updateCalendarRange();
  }

  prevDay(): void {
    this.selectedDate = moment(this.selectedDate).add(-1, 'days').toDate();
    this.updateCalendarRange();
  }

}
