import { BASE_PATH } from './../environments/environment';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Login, LoginResult, RefreshToken, UserInfo, Facility, PresenceInfo, TotalizerElement } from './auth';
import { map, share } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Dictionary } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginUrl = BASE_PATH + '/auth/oauth/web/';
  private refreshUrl = BASE_PATH + '/auth/oauth/token/';
  private meUrl = BASE_PATH + '/api/users/me/';
  private totalizerUrl = BASE_PATH + '/api/totalizer/';
  private searchUrl = BASE_PATH + '/api/search/';
  private whitelistUrl = BASE_PATH + '/api/whitelist/';
  private closureUrl = BASE_PATH + '/api/closure/';
  private facilityUrl = BASE_PATH + '/router/facilities/';
  private walletUrl = BASE_PATH + '/api/wallet/';
  private toggleUrl = BASE_PATH + '/api/toggle/';
  private presenceURL = BASE_PATH + '/api/presence/';
  private termsUrl = BASE_PATH + '/api/terms/'

  private userName: string = null;
  public userLoggedIn: EventEmitter<UserInfo> = new EventEmitter();
  public userLoggedOut: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) { }

  // getOptions() {
  //     let user = this.getUser();
  //     if(user == null) return null;
  //     const auth = `${user.token_type} ${user.access_token}`;
  //     const httpOptions = { headers: new HttpHeaders({ 'Content-Type'  : 'application-json, 'Authorization' : 
  //   }

  // refreshToken() {
  //   let user = this.getUser();
  //   if (user == null) return null;
  //   return user.refresh_token;
  // }

  refreshToken(): Observable<String> {
    let refreshToken = new RefreshToken();
    refreshToken.refresh_token = this.getUser().refresh_token;
    return this.http.post<LoginResult>(this.refreshUrl, refreshToken, {
      headers: new HttpHeaders().set('authExempt', 'true'),
      observe: 'response'
    })
      .pipe(
        share(), // <========== YOU HAVE TO SHARE THIS OBSERVABLE TO AVOID MULTIPLE REQUEST BEING SENT SIMULTANEOUSLY
        map(res => {
          this.setToken(res.body);
          const token = `${res.body.token_type} ${res.body.access_token}`;
          return token;
        })
      );
  }

  getToken() {
    let user = this.getUser();

    const token = `${user.token_type} ${user.access_token}`;
    const isTokenExpired = false;

    if (!isTokenExpired) {
      return of(token);
    }

    return this.refreshToken();
  }

  isLogged(): boolean {
    return this.getUser() != null;
  }

  rememberUser(userName: string): void {
    localStorage.setItem("userName", userName.toString());
  }

  getRememberedUser(): string {
    return localStorage.getItem("userName");
  }

  login(username: string, password: string): Observable<LoginResult> {
    let user = new Login();
    user.username = username;
    user.password = password;
    const httpOptions = { headers: new HttpHeaders({ 'authExempt': 'true' }) };
    return this.http.post<LoginResult>(this.loginUrl, user, httpOptions).pipe(
      map(item => new LoginResult().deserialize(item))
    );
  }

  getUser(): LoginResult {
    let localUser = sessionStorage.getItem("token");
    if (localUser == null) return null;
    let user = JSON.parse(localUser);
    return user;
  }

  setToken(token: LoginResult) {
    sessionStorage.setItem("token", JSON.stringify(token));
  }

  getUserInfoRemote(): Observable<UserInfo> {
    return this.http.get<UserInfo>(this.meUrl).pipe(
      map(item => new UserInfo().deserialize(item))
    );
  }

  getUserInfo(): UserInfo {
    let localUser = sessionStorage.getItem("userInfo");
    if (localUser == null) return null;
    let user = JSON.parse(localUser);
    return user;
  }

  putUserInfoRemote(user: UserInfo): Observable<UserInfo> {
    const url = `${this.meUrl}/${user.id}/`;
    return this.http.put(url, user).pipe(
      map(item => new UserInfo().deserialize(item))
    );
  }

  setUserInfo(userInfo: UserInfo) {
    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    this.userLoggedIn.emit(userInfo);
  }

  resetUserInfo(userInfo: UserInfo) {
    sessionStorage.removeItem("userInfo")
    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
  }

  setFacility(facility: Facility) {
    sessionStorage.setItem("facility", JSON.stringify(facility));
  }

  resetFacility(facility: Facility) {
    sessionStorage.removeItem("facility")
    sessionStorage.setItem("facility", JSON.stringify(facility));
  }

  getFacility(): Facility {
    let localFacility = sessionStorage.getItem("facility");
    if (localFacility == null) return null;
    let facility = JSON.parse(localFacility);
    return facility;
  }

  getPresenceRemote(date: string,): Observable<PresenceInfo> {
    const url = `${this.presenceURL}/?checkin=${date}`;
    return this.http.get<PresenceInfo>(url).pipe(
      map(item => new PresenceInfo().deserialize(item))
    );
  }

  logout() {
    sessionStorage.clear();
    this.userLoggedOut.emit();
  }

  getMyWalletStripeRemote(): Observable<any> {
    const url = `${this.walletUrl}/`;
    return this.http.get(url).pipe();
  }

  getFacilityRemote(): Observable<Facility> {
    let facility = this.getUserInfo().facility;
    const url = `${this.facilityUrl}${facility}/`;
    return this.http.get<Facility>(url).pipe(
      map(item => new Facility().deserialize(item))
    );
  }

  toggleFacilityRemote(): Observable<Facility> {
    const url = `${this.toggleUrl}/`;
    return this.http.put(url, {}).pipe(
      map(item => new Facility().deserialize(item))
    );
  }

  putFacilityRemote(facility: Facility): Observable<Facility> {
    const url = `${this.facilityUrl}/${facility.id}/`;
    return this.http.put(url, facility).pipe(
      map(item => new Facility().deserialize(item))
    );
  }


  getWhitelistRemote(id_facility: number, search: string = null,): Observable<UserInfo[]> {
    let url = `${this.whitelistUrl}`;
    return this.http.get<UserInfo[]>(url).pipe(
      map(items => items.map(item => new UserInfo().deserialize(item)))
    );
  }

  getTotalizerRemote(date: string,): Observable<TotalizerElement[]> {
    let url = `${this.totalizerUrl}?checkin=${date}`;
    return this.http.get<TotalizerElement[]>(url).pipe(
      map(items => items.map(item => new TotalizerElement().deserialize(item)))
    );
  }

  getSearchRemote(id_facility: number, search: string = null,): Observable<UserInfo[]> {
    let url = `${this.searchUrl}/?facility=${id_facility}&q=${search}`;
    return this.http.get<UserInfo[]>(url).pipe(
      map(items => items.map(item => new UserInfo().deserialize(item)))
    );
  }

  postWhitelistRemote(email: string): Observable<any> {
    let url = `${this.whitelistUrl}`;
    let data = {
      email: email
    }
    return this.http.post<UserInfo[]>(url, data);
  }

  deleteWhitelistRemote(email: string): Observable<any> {
    let url = `${this.whitelistUrl}`;
    let params = {
      params: {email: email}
    }
    return this.http.delete<UserInfo[]>(url, params);
  }


  postClosureDateRemote(dal: string, al: string, id_facility: number): Observable<any> {
    let url = `${this.closureUrl}/`;
    let data = {
      date_start: dal,
      date_end: al,
      facility: id_facility
    }
    return this.http.post<UserInfo[]>(url, data);
  }

  removeClosureDateRemote(id_closure: number, id_facility: number): Observable<any> {
    let url = `${this.closureUrl}/`;
    let data = {
      closure: id_closure,
      facility: id_facility
    }
    return this.http.post<UserInfo[]>(url, data);
  }


  getTerms() {
    let url = `${this.termsUrl}`;
    return this.http.get(url, { responseType: 'text' })
  }

}
